import { mapTypeToOldId } from "../constants/types"
import { numberOfWordsInSentence } from "./utils"

function newUpdateEditor(editor, value, analysisResult, json) {
  editor.innerHTML = ""
  // loop over json.sentences
  let all_spans = []
  for (let i = 0; i < json.sentences.length; i++) {
    const entityId = mapTypeToOldId(json.entities[i])
    // console.log("sentence: '" + json.sentences[i] + "'")
    // let innerSpan = React.createElement("span", {}, json.sentences[i].replace('\n', '<br/>'));
    // let outerSpan = React.createElement("span", {className: "annotation-" + json.entities[i]}, innerSpan);
    // all_spans.push(outerSpan);
    let string =
      '<span class="whitespace-pre-line annotation-' +
      entityId +
      '" data-label="' +
      entityId +
      '" data-id="' +
      i +
      '">'
    string += json.sentences[i].replaceAll("\n", "<br/>")
    //string += json.sentences[i]
    string += "</span> "
    // console.log("added to inner: '" + string + "'")
    editor.innerHTML += string
  }

  console.log("updateEditor done")
  // ReactDOM.render(all_spans, editor);
}

// this one has bad side effects
export function updateEditorWithJSONFromServer(dp, json) {
  var editor = document.getElementById("text-editor")
  var value = editor.innerText
  // var value = editor.textContent
  // map json to start and length
  let does_sentence_i_end_with_dot = []
  // let sentences = json.sentences.map((sentence, idx) => {
  //   if (sentence.endsWith(".")) {
  //     does_sentence_i_end_with_dot.push(true)
  //     return sentence.substring(0, sentence.length - 1);
  //   }
  //   does_sentence_i_end_with_dot.push(false)
  //   return sentence;
  // });
  let sentences = json.sentences
  // sentences = sentences.map((sentence, idx) => {
  //   console.log(sentence, sentence.startsWith("\n"))
  //   if (sentence.startsWith("\n") && sentence.length > 1) {
  //     return sentence.substring(1, sentence.length);
  //   }
  //   return sentence;
  // });
  // for (let i = 0; i < sentences.length; i++) {
  //   console.log("i: " + i + " '" + sentences[i] + "'");
  // }
  let analysisResult = []
  let offset = 0
  let majorClaimLen = 0
  let majorClaimWords = 0
  let conclusionLen = 0
  let conclusionWords = 0
  let definitionLen = 0
  let definitionWords = 0
  let subsumptionLen = 0
  let subsumptionWords = 0
  let legalClaimLen = 0
  let legalClaimWords = 0
  let premiseLen = 0
  let premiseWords = 0
  let otherLen = 0
  let otherWords = 0
  let totalLen = 0
  let totalWords = 0
  for (let i = 0; i < sentences.length; i++) {
    const sentence = sentences[i]
    totalLen += sentence.length
    totalWords += numberOfWordsInSentence(sentence)
    analysisResult.push({
      start: offset,
      length: sentence.length,
      id: i,
      label: json.entities[i],
    })
    if (json.entities[i] === "obersatz") {
      majorClaimLen += sentence.length
      majorClaimWords += numberOfWordsInSentence(sentence)
    } else if (json.entities[i] === "konklusion") {
      conclusionLen += sentence.length
      conclusionWords += numberOfWordsInSentence(sentence)
    } else if (json.entities[i] === "definition") {
      definitionLen += sentence.length
      definitionWords += numberOfWordsInSentence(sentence)
    } else if (json.entities[i] === "subsumtion") {
      subsumptionLen += sentence.length
      subsumptionWords += numberOfWordsInSentence(sentence)
    } else if (json.entities[i] === "claim") {
      legalClaimLen += sentence.length
      legalClaimWords += numberOfWordsInSentence(sentence)
    } else if (json.entities[i] === "prämisse") {
      premiseLen += sentence.length
      premiseWords += numberOfWordsInSentence(sentence)
    } else {
      otherLen += sentence.length
      otherWords += numberOfWordsInSentence(sentence)
    }
    if (does_sentence_i_end_with_dot[i]) {
      offset += sentence.length + 2
    } else {
      offset += sentence.length + 1
    }
  }
  // console.log(sentences)

  const shouldUpdateEditorHighlights = dp !== 2 && dp !== 4
  if (shouldUpdateEditorHighlights) {
    newUpdateEditor(editor, value, analysisResult, json)
  }
  
  // updateEditor(editor, value, analysisResult);
  // glaube das wording percentage ist hier nicht richtig oder?
  return {
    majorClaimPercentage: majorClaimWords,
    conclusionPercentage: conclusionWords,
    definitionPercentage: definitionWords,
    subsumptionPercentage: subsumptionWords + legalClaimWords + premiseWords,
    legalClaimPercentage: legalClaimWords,
    premisePercentage: premiseWords,
    otherPercentage: json["persuasiveness"], //100 - Math.round(100 * otherWords / totalWords), // in percentage
  }
}

export function updateCounts(data) {
  let majorClaimCount = 0
  let majorClaimErrors = 0
  let conclusionCount = 0
  let conclusionErrors = 0
  let definitionCount = 0
  let definitionErrors = 0
  let subsumptionCount = 0
  let subsumptionErrors = 0
  let legalClaimCount = 0
  let legalClaimErrors = 0
  let premiseCount = 0
  let premiseErrors = 0
  if (data.are_major_claim_and_conclusion_related === false) {
    majorClaimErrors = 1
    conclusionErrors = 1
  }
  let previousType = 0
  for (let i = 0; i < data.entities.length; i++) {
    let entity = data.entities[i]
    //if (entity === "obersatz" || i === 0) {
    if (entity === "obersatz") {
      majorClaimCount += 1
    } else if (entity === "konklusion") {
      conclusionCount += 1
    } else if (entity === "definition") {
      definitionCount += 1
    } else if (entity === "subsumption") {
      subsumptionCount += 1
    } else if (entity === "claim") {
      legalClaimCount += 1
      if (
        !isClaimInPremiseClaimRelationsArray(i, data.premise_claim_relations)
      ) {
        legalClaimErrors += 1
      }
    } else if (entity === "prämisse") {
      premiseCount += 1
      if (
        !isPremiseInPremiseClaimRelationsArray(i, data.premise_claim_relations)
      ) {
        premiseErrors += 1
      }
    }
  }

  return {
    majorClaimCount: majorClaimCount,
    majorClaimErrors: majorClaimErrors,
    conclusionCount: conclusionCount,
    conclusionErrors: conclusionErrors,
    definitionCount: definitionCount,
    definitionErrors: definitionErrors,
    subsumptionCount: subsumptionCount,
    subsumptionErrors: subsumptionErrors,
    legalClaimCount: legalClaimCount,
    legalClaimErrors: legalClaimErrors,
    premiseCount: premiseCount,
    premiseErrors: premiseErrors,
  }
}

function isPremiseInPremiseClaimRelationsArray(
  premiseIndex,
  premiseClaimRelationsArray
) {
  for (let i = 0; i < premiseClaimRelationsArray.length; i++) {
    if (premiseClaimRelationsArray[i][1] === premiseIndex) {
      return true
    }
  }
  return false
}

function isClaimInPremiseClaimRelationsArray(
  claimIndex,
  premiseClaimRelationsArray
) {
  for (let i = 0; i < premiseClaimRelationsArray.length; i++) {
    if (premiseClaimRelationsArray[i][0] === claimIndex) {
      return true
    }
  }
  return false
}
