import { TOKEN_LOCAL_STORAGE_ID } from "../constants/api"
import { textIdUrl, userTextsUrl } from "../constants/env"

import { z } from "zod"

const LatestTextResponse = z.object({ text: z.string(), createdAt: z.string() })

export async function getLatestTextForId(textId) {
  try {
    const resp = await fetch(textIdUrl + `?id=${textId}`, {
      method: "GET",
    })

    const data = await resp.json()
    const parsedData = LatestTextResponse.parse(data)
    console.log(parsedData)

    return {
      text: parsedData.text || "",
      createdAt: parsedData.createdAt || "",
    }
  } catch (err) {
    console.log("error occured during getlatesttextforid")
    console.log(err)
  }

  return undefined
}

const UserTextsResponse = z.object({
  userTexts: z.array(z.object({ id: z.string(), createdAt: z.string() })),
})

export async function getUserTexts() {
  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_ID)

  try {
    const resp = await fetch(userTextsUrl, {
      method: "GET",
      headers: {
        "x-access-tokens": token,
      },
    })

    const data = await resp.json()
    const parsedData = UserTextsResponse.parse(data)

    return parsedData.userTexts
  } catch (err) {
    console.log(err)
  }

  return { error: "Unable to retrieve user texts." }
}
