import { useRecoilState } from "recoil"
import LawrgueLogoPng from "../lawrgue.png"
import { dpGroupState } from "../state/recoil"
import { getYoutubeVideoLink } from "../lib/dp"

export default function LogoWrapper({ version }) {
  const [dpGroup] = useRecoilState(dpGroupState)

  const youtubeLink = getYoutubeVideoLink(dpGroup)

  return (
    <div className="bg-white rounded w-full p-1">
      <div
        className="flex gap-2 font-bold justify-center w-full"
        style={{ height: 40 }}>
        <img src={LawrgueLogoPng} alt="Legal writer logo" />
      </div>
      <a href={youtubeLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 text-center w-full block">Videoerklärung (Youtube)</a>
    </div>
  )
}