import React from "react"
import HtmlTooltip, {
  TooltipText,
  TooltipTitle,
} from "../../components/HtmlTooltip"
import { LEGAL_TYPE_SEPARATOR, getExplanationTextForType, getExplanationTitleForType, getLabelForType } from "../../constants/types"
import classnames from "classnames"

export default function PlanItemButton({
  id,
  backgroundColor,
  addNewPlanItem,
  idx = -1,
  small,
  className,
  interactive = false,
}) {
  function onClick() {
    if (!interactive) return
    addNewPlanItem(id)
  }

  return (
    <div
      className={classnames(
        "flex rounded justify-center items-center text-center",
        {
          "w-full": id === LEGAL_TYPE_SEPARATOR,
          "p-1": small,
          "p-2": !small,
          "text-sm": small,
          "h-8": small,
        },
        className
      )}
      style={{ backgroundColor, minWidth: 120 }}
      onClick={onClick}>
      {getLabelForType(id)}
    </div>
  )
}

export function PlanItemButtonWithTooltip({ id: type, ...props }) {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <TooltipTitle>{getExplanationTitleForType(type)}</TooltipTitle>
          <TooltipText>{getExplanationTextForType(type)}</TooltipText>
        </React.Fragment>
      }>
      <div>
        <PlanItemButton {...props} id={type} />
      </div>
    </HtmlTooltip>
  )
}
