import * as React from "react"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { paragraphIntroText, paragraphs } from "../constants/paragraphs"
import { CircularProgress } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { getLatestTextForId } from "../lib/texts"

export default function SubmissionPage() {
  const [submissionData, setSubmissionData] = React.useState()
  const [submissionDataLoading, setSubmissionDataLoading] =
    React.useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get("id")

  React.useEffect(() => {
    async function inner() {
      setSubmissionDataLoading(true)

      const textData = await getLatestTextForId(id)
      setSubmissionData({ ...textData })

      setSubmissionDataLoading(false)
    }

    inner()
  }, [id])

  return (
    <div className="p-4 mt-8">
      {!submissionData && !submissionDataLoading && (
        <Typography className="text-red-600">
          Keine Abgabe mit der Id {id} gefunden.
        </Typography>
      )}
      {submissionDataLoading && <CircularProgress />}
      {submissionData && (
        <div className="max-w-7xl mx-auto mb-8">
          <Typography variant="h4" className="!mb-4">
            Deine Abgabe vom {submissionData.createdAt}
          </Typography>
          <div className="bg-white drop-shadow-sm p-8 rounded">
            <Typography className="whitespace-pre-line">{submissionData.text}</Typography>
          </div>
        </div>
      )}
    </div>
  )
}
