import * as React from "react"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useRecoilState } from "recoil"
import { languageState } from "../state/recoil"
import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export default function EditorHeader() {
  const [currentUpperPanelContentType, setCurrentUpperPanelContentType] =
    React.useState("none") // none / caseStudy / paragraphen
  const [caseStudyContents, setCaseStudyContents] = React.useState("")
  const [currentLanguage] = useRecoilState(languageState)

  const toggleCaseStudyState = () => {
    setCaseStudyContents(localStorage.getItem("caseStudyValue").trim())
    if (currentUpperPanelContentType === "caseStudy") {
      setCurrentUpperPanelContentType("none")
    } else {
      setCurrentUpperPanelContentType("caseStudy")
    }
  }
  const toggleParagraphenState = () => {
    setCaseStudyContents(localStorage.getItem("usefulParagraphsValue").trim())
    if (currentUpperPanelContentType === "paragraphen") {
      setCurrentUpperPanelContentType("none")
    } else {
      setCurrentUpperPanelContentType("paragraphen")
    }
  }
  return (
    <>
      {currentUpperPanelContentType === "caseStudy" && (
        <div className="text-justify bg-white p-4 text-sm">
          <Typography>{caseStudyContents}</Typography>
        </div>
      )}
      {currentUpperPanelContentType === "paragraphen" && (
        <div className="text-center bg-white p-4 text-lg">
          <Typography>{caseStudyContents}</Typography>
          <Link
            className="mt-1 text-blue-600 hover:text-blue-800 transition"
            to="/paragraphs"
            target="_blank"
            rel="noopener noreferrer">
            Für die Paragraphentexte, klicke hier.
          </Link>
        </div>
      )}
      <Box
        className="bg-white rounded"
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            marginRight: 40,
          }}
          onClick={toggleCaseStudyState}>
          <div style={{ fontSize: 30, fontWeight: "bold", marginRight: 25 }}>
            {currentLanguage === "en"
              ? (currentUpperPanelContentType !== "none" ? "Hide" : "Show") +
                " case study"
              : "Fall"}
          </div>
          {currentUpperPanelContentType !== "none" ? (
            <ArrowUpwardIcon style={{ fontSize: 35 }} />
          ) : (
            <ArrowDownwardIcon style={{ fontSize: 35 }} />
          )}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={toggleParagraphenState}
          className="useful-paragraphs">
          <div style={{ fontSize: 30, fontWeight: "bold", marginRight: 25 }}>
            {currentLanguage === "en"
              ? "Useful paragraphs"
              : "Nützliche Paragraphen"}
          </div>
          {currentUpperPanelContentType !== "none" ? (
            <ArrowUpwardIcon style={{ fontSize: 35 }} />
          ) : (
            <ArrowDownwardIcon style={{ fontSize: 35 }} />
          )}
        </div>
      </Box>
    </>
  )
}
