import classNames from "classnames"

export default function PaneText({ children, className, small }) {
  return (
    <p
      className={classNames(
        "text-gray-600",
        { "text-sm": small, "text-base": !small },
        className
      )}>
      {children}
    </p>
  )
}
