import * as React from "react"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import getCoursesWithCaseStudies, {
  courseOverviewIntroText,
} from "../../constants/courses"
import { Link, useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { dpGroupState } from "../../state/recoil"
import { hasPlan } from "../../lib/dp"
import { CourseWithCaseStudy } from "./CaseStudy"
import { getUserTexts } from "../../lib/texts"
import { formatDate } from "../../lib/date"
import { Typography } from "@mui/material"

function CoursesPage() {
  const navigate = useNavigate()
  const [rows, setRows] = React.useState([])
  const [isLoadingIndicatorOpen, setLoadingIndicatorOpen] =
    React.useState(false)
  const [dpGroup] = useRecoilState(dpGroupState)
  const coursesWithCaseStudies = getCoursesWithCaseStudies()
  const [existingSubmissions, setExistingSubmissions] = React.useState([])

  React.useEffect(() => {
    async function getUserTextsWrapper() {
      const userTexts = await getUserTexts()

      if (userTexts.hasOwnProperty("error")) {
        setExistingSubmissions([])
      } else {
        const dateFormattedUserTexts = userTexts.map(ut => ({
          ...ut,
          createdAt: formatDate(new Date(ut.createdAt)),
        }))
        setExistingSubmissions(dateFormattedUserTexts)
      }
    }

    getUserTextsWrapper()
  }, [])

  const caseStudyClicked = caseStudy => {
    let caseStudyValue = caseStudy.caseStudy
    let usefulParagraphsValue = caseStudy.usefulParagraphs
    localStorage.setItem("caseStudyValue", caseStudyValue)
    localStorage.setItem("usefulParagraphsValue", usefulParagraphsValue)

    if (hasPlan(dpGroup)) {
      navigate("/plan")
    } else {
      navigate("/main")
    }
  }

  return (
    <>
      <div className="p-4 mt-8">
        <div className="max-w-7xl mx-auto mb-8">
          <Typography variant="h4" className="!mb-2">
            Kursübersicht
          </Typography>
          <Typography>{courseOverviewIntroText}</Typography>
          <div className="mt-4">
            <div className="flex gap-8">
              {coursesWithCaseStudies.map((course, idx) => {
                return (
                  <CourseWithCaseStudy
                    key={`course-cases-${course.courseName}`}
                    course={course}
                    caseStudyClicked={caseStudyClicked}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto mb-8">
          <Typography variant="h4" className="!mb-2">
            Deine vorherigen Abgaben
          </Typography>
          <div>
            {existingSubmissions.length === 0 && (
              <Typography>Keine Abgaben gefunden.</Typography>
            )}
            {existingSubmissions.length > 0 && (
              <div className="flex flex-col gap-2">
                {existingSubmissions.map(subm => (
                  <Link
                    className="text-blue-600 hover:text-blue-800 transition"
                    to={`/submission?id=${subm.id}`}>
                    Abgabe vom {subm.createdAt}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoadingIndicatorOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default CoursesPage
