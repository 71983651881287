import "./plan.css"
import { useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import ExamplePlanPane from "../../components/panes/ExamplePlanPane"
import LeftPlanPane from "./LeftPlanPane"
import MiddlePlanPane from "./MiddlePlanPane"
import { v4 as uuidv4 } from "uuid"
import {
  ERROR_CONTINUOUS_NUMBER,
  ERROR_DUPLICATE_COMPONENTS,
  ERROR_HAS_KONKLUSION_NO_OBERSATZ,
  ERROR_HAS_KONKLUSION_NO_SUBSUMPTION,
  ERROR_HAS_OBERSATZ_NO_DEFINITION,
  ERROR_HAS_OBERSATZ_NO_KONKLUSION,
  ERROR_NO_SUBSUMPTION,
  ERROR_ORDER_WRONG,
} from "../../constants/plan"
import {
  LEGAL_TYPE_DEFINITION,
  LEGAL_TYPE_KONKLUSION,
  LEGAL_TYPE_OBERSATZ,
  LEGAL_TYPE_SUBSUMPTION,
  LEGAL_TYPE_UEBERSCHRIFT,
} from "../../constants/types"
import { checkComponentOrder } from "../../lib/utils"
import { useRecoilState } from "recoil"
import { planItemsState } from "../../state/recoil"
import AppWrapper from "../../components/AppWrapper"
import LogoWrapper from "../../components/LogoWrapper"

export default function Plan({ version }) {
  const [planItems, setPlanItems] = useRecoilState(planItemsState)
  const [planErrors, setPlanErrors] = useState()
  const [planPaneOpen, setPlanPaneOpen] = useState(true)
  const [examplePaneOpen, setExamplePaneOpen] = useState(false)

  function addNewPlanItem(type) {
    let newCounter = 1
    let isObersatzAndHasNoUeberschriftBefore = false
    if (planItems.length > 0) {
      const last = planItems.slice(-1)[0]

      if (
        type === LEGAL_TYPE_OBERSATZ &&
        last.type !== LEGAL_TYPE_UEBERSCHRIFT
      ) {
        newCounter = last.manualCounter + 1
        isObersatzAndHasNoUeberschriftBefore = true
      } else {
        newCounter =
          type === LEGAL_TYPE_UEBERSCHRIFT
            ? last.manualCounter + 1
            : last.manualCounter
      }
    } else if (type === LEGAL_TYPE_OBERSATZ) {
      isObersatzAndHasNoUeberschriftBefore = true
    }

    const newItems = []
    if (isObersatzAndHasNoUeberschriftBefore) {
      newItems.push({
        id: uuidv4(),
        type: LEGAL_TYPE_UEBERSCHRIFT,
        text: "",
        manualCounter: newCounter,
      })
    }

    const newPlanItem = {
      id: uuidv4(),
      type,
      text: "",
      manualCounter: newCounter,
    }
    newItems.push(newPlanItem)
    const newPlanItems = [...planItems, ...newItems]

    setPlanItemsIndexed(newPlanItems)
  }

  function updatePlanItem(planItem, idx) {
    const newPlanItem = { ...planItem }

    const newPlanItems = [...planItems]
    newPlanItems[idx] = newPlanItem

    setPlanItemsIndexed(newPlanItems)
  }

  function setPlanItemsIndexed(planItems) {
    let ueberschriftCurrentNumber = 0

    const newPlanItems = planItems.map(pi => {
      const newPi = { ...pi }

      if (newPi.type === LEGAL_TYPE_UEBERSCHRIFT) {
        ueberschriftCurrentNumber = newPi.manualCounter
      } else {
        newPi.manualCounter = ueberschriftCurrentNumber
      }

      return newPi
    })

    setPlanItems(newPlanItems)
  }

  function validatePlanItems() {
    const errors = []

    const counterMap = {}

    let maxManualCounter = 0

    planItems.forEach(pi => {
      const manualCounter = pi["manualCounter"]
      if (!counterMap.hasOwnProperty(manualCounter)) {
        counterMap[manualCounter] = []
      }
      counterMap[manualCounter].push(pi)

      if (maxManualCounter < manualCounter) {
        maxManualCounter = manualCounter
      }
    })

    for (let i = 0; i < maxManualCounter; i++) {
      if (!counterMap.hasOwnProperty(i + 1)) {
        errors.push({ text: ERROR_CONTINUOUS_NUMBER, counter: -1 })
        setPlanErrors(errors)
        return
      }
    }

    for (const [counter, pis] of Object.entries(counterMap)) {
      const componentInnerCounterMap = {
        [LEGAL_TYPE_DEFINITION]: 0,
        [LEGAL_TYPE_SUBSUMPTION]: 0,
        [LEGAL_TYPE_KONKLUSION]: 0,
        [LEGAL_TYPE_UEBERSCHRIFT]: 0,
        [LEGAL_TYPE_OBERSATZ]: 0,
      }

      pis.forEach(pi => (componentInnerCounterMap[pi.type] += 1))

      const hasObersatz = componentInnerCounterMap[LEGAL_TYPE_OBERSATZ] > 0
      const hasDefinition = componentInnerCounterMap[LEGAL_TYPE_DEFINITION] > 0
      const hasSubsumption =
        componentInnerCounterMap[LEGAL_TYPE_SUBSUMPTION] > 0
      const hasKonklusion = componentInnerCounterMap[LEGAL_TYPE_KONKLUSION] > 0

      const correctOrder = []
      if (hasObersatz) {
        correctOrder.push(LEGAL_TYPE_OBERSATZ)
      }
      if (hasDefinition) {
        correctOrder.push(LEGAL_TYPE_DEFINITION)
      }
      if (hasSubsumption) {
        correctOrder.push(LEGAL_TYPE_SUBSUMPTION)
      }
      if (hasKonklusion) {
        correctOrder.push(LEGAL_TYPE_KONKLUSION)
      }
      const actualOrder = pis
        .map(pi => pi.type)
        .filter(type => type !== LEGAL_TYPE_UEBERSCHRIFT)

      const hasCorrectOrder = checkComponentOrder(correctOrder, actualOrder)
      if (!hasCorrectOrder) {
        errors.push({ text: ERROR_ORDER_WRONG, counter })
      }

      let hasDuplicates = false
      Object.entries(componentInnerCounterMap).forEach(([type, count]) => {
        if (type !== LEGAL_TYPE_UEBERSCHRIFT && count > 1) {
          hasDuplicates = true
        }
      })

      if (hasObersatz && !hasKonklusion) {
        errors.push({ text: ERROR_HAS_OBERSATZ_NO_KONKLUSION, counter })
      }
      if (hasKonklusion && !hasObersatz) {
        errors.push({ text: ERROR_HAS_KONKLUSION_NO_OBERSATZ, counter })
      }
      if (hasObersatz && !hasDefinition) {
        errors.push({ text: ERROR_HAS_OBERSATZ_NO_DEFINITION, counter })
      }
      if (hasKonklusion && !hasSubsumption) {
        errors.push({ text: ERROR_HAS_KONKLUSION_NO_SUBSUMPTION, counter })
      }
      if (!hasSubsumption) {
        errors.push({ text: ERROR_NO_SUBSUMPTION, counter })
      }
      if (hasDuplicates) {
        errors.push({ text: ERROR_DUPLICATE_COMPONENTS, counter })
      }
    }

    setPlanErrors(errors)
  }

  return (
    <AppWrapper>
      <Grid container spacing={2}>
        <Grid item xs={3} xm={3} xl={3}>
          <div className="flex flex-col gap-2">
            <LogoWrapper />
            <LeftPlanPane
              addNewPlanItem={addNewPlanItem}
              open={planPaneOpen}
              setOpen={setPlanPaneOpen}
            />
          </div>
        </Grid>
        <Grid item xs={6} xm={6} xl={6}>
          <MiddlePlanPane
            version={version}
            planItems={planItems}
            setPlanItems={setPlanItemsIndexed}
            updatePlanItem={updatePlanItem}
            validatePlanItems={validatePlanItems}
            planErrors={planErrors}
          />
        </Grid>
        <Grid item xs={3} xm={3} xl={3}>
          <ExamplePlanPane
            open={examplePaneOpen}
            setOpen={setExamplePaneOpen}
          />
        </Grid>
      </Grid>
    </AppWrapper>
  )
}
