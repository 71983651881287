import { Divider, Typography } from "@mui/material"

export function CourseWithCaseStudy({ course, caseStudyClicked }) {
  return (
    <div key={`caseStudy-${course.courseName}`} className="bg-white rounded p-8 drop-shadow">
      <Typography variant="h5" className="!mb-1">{course.courseName}</Typography>
      <Divider light />
      <div className="flex flex-col gap-1 mt-2">
        {course.cases.map(caseStudy => {
          return (
            <div key={`casestudy-${caseStudy.caseName}`}>
              <a
                className="text-blue-600 hover:text-blue-800 transition-all cursor-pointer"
                onClick={() => {
                  caseStudyClicked(caseStudy)
                }}>
                {caseStudy.caseName}
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}
