// const caseStudies = [
//     {
//         "courseName": "Course 1",
//         "cases": [
//             {
//                 "caseName": "Case 1",
//                 "caseStudy": "Case Study 1",
//                 "usefulParagraphs": "Useful Paragraphs 1"
//             }
//         ]
//     }
// ]

export const courseOverviewIntroText = "Wähle hier den Kurs für den du deine Abgabe erstellen möchtest."

const coursesWithCaseStudies = [
  {
    courseName: "EidrA",
    cases: [
      {
        caseName: "Fallübung 1 - Tattoo",
        caseStudy: `K wurde am 3. August 2001 geboren und ist seit einigen Jahren große Verehrerin einer erfolgreichen Rockband.
Dabei gefällt ihr nicht nur die Musik, sondern auch, dass die Mitglieder der Band allesamt zahlreiche
Tätowierungen am Körper tragen. Auch K ist von der Idee fasziniert, ein solches Kunstwerk auf der Haut
zu tragen und möchte sich, als Ausdruck ihrer Persönlichkeit, ebenfalls ein Tattoo stechen lassen. Das Motiv
ist schnell gefunden, es soll das Logo der Rockband sein, das ihr Handgelenk zieren soll.
K lebt mit ihrem Vater V zusammen. Er ist allein sorgeberechtigt und kommt für Ks Unterhalt auf. Zusätzlich
zahlt er ihr ein monatliches Taschengeld von 50 €. Um sich noch etwas dazu zu verdienen, jobbt K in
den Ferien in einem Café, was mit V abgesprochen ist.
K weiß, dass V Tattoos grundsätzlich ablehnt. Als sie früher einmal laut überlegt hatte, welches Tattoo ihr
wohl stehen würde, sagte er genervt, dass diese Tätowierungen ein furchtbarer Trend seien und er überhaupt
nicht verstehe, warum auf einmal alle jungen Leute meinten, sie müssten sich tätowieren lassen.
Spätestens nach zwei Wochen wäre ohnehin ein anderes Motiv „en vogue“ und Entfernungen seien
schmerzhaft und teuer. Davon solle man besser die Finger lassen.
Als Tätowiererin T, deren Stil und Motive K gut gefallen, am Ende der Sommerferien ein neues Tattoo-
Studio in der Stadt eröffnet, erkundigt sich K nach dem Preis. T ist erfreut über die neue Kundin und bietet
ihr das gewünschte Tattoo zu einem Preis von 100 € inklusive aller Nachuntersuchungen an. K, die in der
Saison etwas Geld verdient hat, stimmt begeistert zu. Schon am nächsten Tag, dem 21. August 2018, lässt
sie sich das besprochene Motiv stechen und bezahlt den Preis vollständig in bar. Um eine Diskussion zu
vermeiden, hatte sie V gegenüber nichts von ihrem Vorhaben erwähnt.
Als K einen Tag später zu Hause etwas verlegen V die Verzierung präsentiert und dieser erfährt, wie viel
sie gekostet hat, ist er entsetzt. Er als erziehungsberechtigter Vater hätte doch wohl seine Einwilligung
erteilen müssen. Zudem wüsste K, was er von solcher „Kunst“ halte. Als Erziehungsberechtigter habe er
außerdem die Verantwortung dafür, dass sie den richtigen Umgang mit Geld lerne und keine unbedachten
Entscheidungen treffe. Ohne seine Zustimmung könne sie kein Rechtsgeschäft tätigen. Die 100 € seien
von T zurückzuzahlen. K kann die Aufregung nicht verstehen und findet, dass sie reif genug für eine solche
Entscheidung ist. Außerdem habe sie das Geld selbst verdient und könne es so ausgeben, wie sie es für
richtig halte.

Frage: Ist der zwischen K und T geschlossene Werkvertrag wirksam?

Bearbeitungshinweis: Der Vertragsschluss (Angebot und Annahme) ist nicht zu prüfen. Es
ist außerdem davon auszugehen, dass K den Betrag je zur Hälfte aus dem Taschengeld und
dem Selbstverdienten bezahlt.

`,
        usefulParagraphs: `§§ 631, 104, 105, 106, 107, 108, 110 BGB
                    

                `,
      },
      {
        caseName: "Fallübung 2 - Vertragsschluss per Video?",
        caseStudy: `K und V treffen sich zu Pandemiezeiten über das Videokonferenzsystem Yoom. V erzählt dem K: „Ich will mir einen neuen elektronisch höhenverstellbaren Schreibtisch zulegen. Um Platz zu schaffen, muss ich noch meinen alten Schreibtisch loswerden.“ K ist schon lange genervt davon am Küchentisch zu arbeiten. Ein günstiger Schreibtisch kommt ihm sehr gelegen, weshalb er zu V sagt: „Wie viel willst du für deinen alten Schreibtisch haben? Ich würde ihn nehmen.“ V entgegnet: „Für 80 € ist er dein.“ Bevor K antworten kann bricht die Verbindung ab. K schreibt sodann in den Chat: „Abgemacht, ich nehme ihn!"
Haben K und V einen wirksamen Kaufvertrag geschlossen?
  `,

        usefulParagraphs: `§ 433 BGB, § 145 ff. BGB, §§ 133, 157 BGB 
                   
                `,
      },
      {
        caseName: "Fallübung 3 - Urheberschaft als Eigenschaft?",
        caseStudy: `
                   Kunstsammler K entdeckt im Trödelladen des V ein Ölgemälde, das mit dem Preis von 100 € ausgezeichnet ist. K hält das Gemälde für ein Original des Künstlers Reese, das mindestens einen Wert von 30.000 € haben dürfte. K, das Geschäft seines Lebens witternd, geht überglücklich zu V und schließt mit ihm einen entsprechenden Kaufvertrag. Als er das Gemälde am nächsten Tag abholen möchte, stellt er bei genauer Untersuchung des Gemäldes fest, dass es doch kein Original Reeses ist und somit tatsächlich auch nur 100 € wert ist. Er teilt dem V umgehend mit, dass er nicht am Kaufvertrag festhalten will.
                   
Hat V gegen K einen Anspruch auf Kaufpreiszahlung?

                `,
        usefulParagraphs: `§ 433 BGB, § 143 Abs. 1 BGB, §§ 133, 157 BGB, § 119 II BGB, § 121 BGB, § 142 BGB
                    
                `,
      },
    ],
  },
  {
    courseName: "Bürgerliches Recht II - Universität Kassel",
    cases: [
      {
        caseName: "Fallübung 1 -  Salatblatt ",
        caseStudy: `
                    Charlotte Clausner muss noch schnell zwei Pakete Magerquark kaufen und eilt in den Supermarkt ihres Vertrauens. Doch aus dem Einkauf wird nichts, denn in der Gemüseabteilung liegt ein riesengroßes Salatblatt auf dem Boden, das Frau Pütz, die  Mitarbeiterin des Supermarkes,  zuvor nicht ordnungsgemäß entfernt hatte. Charlotte Clausner rutscht auf diesem Salatblatt aus, stürzt und bricht sich dabei den Arm. Die Behandlungskosten in Höhe von 800,- € möchte sie nun von Herrn Albrecht, dem Inhaber des Supermarkes, erstattet bekommen. 

Hat sie einen Anspruch darauf?

                `,
        usefulParagraphs: `
                    § 280 BGB
                    
                    § 311 BGB
                    
                    § 278 BGB
                    
                    § 249 BGB
                `,
      },
      {
        caseName: "Fallübung 2 - Höhere Gewalt",
        caseStudy: `
                    Der 17jährige K erblickt im Second-Hand-Laden des V die lang gewünschte Videoanlage, die dort für nur 100 Euro verkauft wird. Diese Summe kann K aus seinem ersparten Taschengeld aufwenden. Folglich kauft er die Anlage und zahlt sofort. V verspricht, am kommenden Tag zu liefern. In der Nacht jedoch brennt das Nachbarhaus aufgrund eines Blitzeinschlages ab. Dabei greifen auch Flammen auf den Laden des V über. Die Videoanlage wird zerstört. Kann K Lieferung oder Ersatz verlangen? Kann K, unterstellt, dass er weder Lieferung noch Schadensersatz erhält, die Rückzahlung der 100 Euro verlangen?

                `,
        usefulParagraphs: `
                    § 110 BGB 
§433 BGB

§§ 107-113 BGB

§ 108 BGB

§ 275 BGB

§ 280 BGB

§ 283 BGB

§ 311 BGB

§ 276 BGB

§ 346 BGB

§ 326 BGB"
                `,
      },
      {
        caseName: "Fallübung 3 - Begehrte Clinton-Story",
        caseStudy: `
                    K betreibt einen kleinen Buchhandel. In Kürze soll sich unter den Neuerscheinungen  u.a. die Biographie von Bill Clinton des X-Verlags (V) befinden.  Mit dem V hat er folgende Vereinbarung getroffen: Sobald ein Band zur Neige geht, bestellt er diesen Band nach; der Verlag liefert dann innerhalb von drei Tagen. 
Zu Verkaufsbeginn strömen die Kunden in sein Geschäft und verlangen die Clinton-Biographie. Das Buch ist schnell ausverkauft, und K bestellt beim X-Verlag 100 weitere Exemplare. Der Verlag beliefert wegen der völlig unerwartet großen Nachfrage erst die größeren Buchhandlungen. K erhält die Bücher erst nach fünf Tagen. Er hat in den zwei Tagen, in den V nicht liefern konnte, 50 Kunden an die Konkurrenz verloren. Nun will K diesen Schaden von V ersetzt haben. V wendet ein, der überraschende Erfolg des Buches sei nicht vorhersehbar gewesen. Außerdem habe man schon deutlich mehr Exemplare gedruckt als die Auflage anderer Biographien beträgt (was stimmt).

Wird das Begehren des K trotz dieser Einwendung Erfolg haben?

                `,
        usefulParagraphs: `
                    § 280 BGB, § 286 BGB, § 276 BGB,
§ 433 BGB, § 252 BGB, § 288 BGB
                `,
      },
    ],
  },
]

export default function getCoursesWithCaseStudies() {
  return coursesWithCaseStudies
}
