import * as React from "react"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  paragraphIntroText,
  paragraphContainers,
} from "../constants/paragraphs"
import Markdown from "react-markdown"

export default function ParagraphsPage() {
  return (
    <div className="p-4 mt-8">
      <div className="max-w-7xl mx-auto mb-8">
        <Typography variant="h4" className="!mb-2">
          Paragraphen
        </Typography>
        <Typography>{paragraphIntroText}</Typography>
      </div>
      <div className="flex flex-col gap-4 justify-center items-center">
        {paragraphContainers.map(paragraphContainer => (
          <div
            className="max-w-7xl w-full"
            key={`paragraphContainer-${paragraphContainer.containerTitle}`}>
            <Typography variant="h4" className="!mb-2">
              {paragraphContainer.containerTitle}
            </Typography>
            {paragraphContainer.paragraphs.map(paragraph => (
              <Accordion key={paragraph.name} className="">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${paragraph.name}-content`}
                  id={`${paragraph.name}-header`}>
                  <Typography fontWeight="bold">{paragraph.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Markdown className="max-w-7xl markdown-stylings">
                    {paragraph.text}
                  </Markdown>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
