export function formatDate(date) {
  const day = date.getDate()
  const month = date.getMonth() + 1 // Months are 0-based, so add 1
  const year = date.getFullYear() % 100 // Get the last two digits of the year
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  // Format the components
  const formattedDate = `${day < 10 ? "0" : ""}${day}.${
    month < 10 ? "0" : ""
  }${month}.${year}, ${hours}:${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds} Uhr`

  return formattedDate
}
