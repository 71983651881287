import { Divider } from "@mui/material"
import classNames from "classnames"
import { Collapse } from "@mui/material"
import { useState } from "react"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

export default function PaneWrapper({
  children,
  title,
  className,
  open,
  setOpen,
}) {
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <div
      className={classNames(
        "bg-white flex flex-col justify-start align-start text-lg rounded p-4",
        className
      )}>
      <div
        className="flex justify-between items-center pr-4"
        onClick={handleClick}>
        <h2 className="font-bold mb-1">{title}</h2>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Divider className="w-full" light />
      <div className="mb-2" />
      <Collapse in={open}>{children}</Collapse>
    </div>
  )
}
