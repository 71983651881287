import React from "react"
import {
  getColorForType,
  getExplanationTextForType,
  getExplanationTitleForType,
  getLabelForType,
} from "../constants/types"
import HtmlTooltip, { TooltipText, TooltipTitle } from "./HtmlTooltip"
import ComponentCircle from "./ComponentCircle"

export default function ComponentCircleExplanation({
  type,
  children,
  labelInCircle,
  small,
}) {
  const label = getLabelForType(type)
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <TooltipTitle>{getExplanationTitleForType(type)}</TooltipTitle>
          <TooltipText>{getExplanationTextForType(type)}</TooltipText>
        </React.Fragment>
      }>
      <div className="flex gap-4 items-center">
        <ComponentCircle
          type={type}
          title={labelInCircle ? label : ""}
          width={small ? 20 : 28}
          height={small ? 20 : 28}
        />
        {!labelInCircle && <p className="text-base">{label}</p>}
        {children}
      </div>
    </HtmlTooltip>
  )
}
