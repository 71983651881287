import { useEffect } from "react"
import { useQuery } from "../lib/customHooks"
import { useRecoilState } from "recoil"
import { demoState, dpGroupState } from "../state/recoil"

export default function DPGroupSetter() {
  const query = useQuery()
  const [, setDpGroup] = useRecoilState(dpGroupState)
  const [, setIsDemo] = useRecoilState(demoState)

  useEffect(() => {
    // console.log("useEffect")
    const dpGroupQuery = query.get("dp")
    const demoQuery = query.get("demo")
    if (demoQuery) {
      if (demoQuery === "true") {
        setIsDemo(true)
        localStorage.setItem("demo", demoQuery)
      } else {
        setIsDemo(false)
        localStorage.removeItem("demo")
      }
    } else {
      const demoCached = localStorage.getItem("dpGroup")
      if (demoCached === "true") {
        setIsDemo(demoCached)
        query.set("demo", "true")
      }
    }

    if (dpGroupQuery) {
      if (!isNaN(dpGroupQuery)) {
        // console.log("set dpGroup dpGroupQuery " + dpGroupQuery)
        setDpGroup(parseInt(dpGroupQuery))
        localStorage.setItem("dpGroup", dpGroupQuery)
      }
    } else {
        const dpGroupCached = localStorage.getItem("dpGroup")
        if (dpGroupCached && !isNaN(dpGroupCached)) {
          // console.log("set dpGroup dpGroupCached " + dpGroupCached)
          setDpGroup(parseInt(dpGroupCached))
        } else {
          setDpGroup(7)
        }
    }
  }, [])

  return null
}
