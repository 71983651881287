export const errorsForMajorClaim = leftPaneState => {
  if (leftPaneState.majorClaimCount < leftPaneState.conclusionCount) {
    return leftPaneState.conclusionCount - leftPaneState.majorClaimCount
  }
  return 0
}

export const errorsForDefinition = leftPaneState => {
  if (leftPaneState.majorClaimCount > leftPaneState.definitionCount) {
    return 1
  }
  return 0
}

export const errorsForConclusion = leftPaneState => {
  if (leftPaneState.majorClaimCount > leftPaneState.conclusionCount) {
    return leftPaneState.majorClaimCount - leftPaneState.conclusionCount
  }
  return 0
}

export const recommendationForMajorClaim = (leftPaneState, currentLanguage) => {
  if (leftPaneState.majorClaimCount + 1 === leftPaneState.conclusionCount) {
    return currentLanguage === "en"
      ? "A conclusion is not opened by a major claim. Formulate a corresponding major claim in the conjunctive."
      : "Eine Konklusion wird nicht durch einen Obersatz eröffnet. Formuliere einen entsprechenden Obersatz zum Beispiel im Konjunktiv. Mögliche weitere Einleitungen, um einen guten Obersatz zu formulieren sind: Fraglich ist, ... oder Zu prüfen gilt es... ."
  }
  if (leftPaneState.majorClaimCount + 2 === leftPaneState.conclusionCount) {
    return currentLanguage === "en"
      ? "Two conclusions are not opened by major claims. Formulate two corresponding major claims in the conjunctive."
      : "Zwei Konklusionen werden nicht durch einen Obersatz eröffnet. Formuliere entsprechende Obersätze zum Beispiel im Konjunktiv. Mögliche weitere Einleitungen, um einen guten Obersatz zu formulieren sind: Fraglich ist, ... oder Zu prüfen gilt es... ."
  }
  if (leftPaneState.majorClaimCount + 3 === leftPaneState.conclusionCount) {
    return currentLanguage === "en"
      ? "Three conclusions are not opened by major claims. Formulate three corresponding major claims in the conjunctive."
      : "Drei Konklusionen werden nicht durch einen Obersatz eröffnet. Formuliere entsprechende Obersätze zum Beispiel im Konjunktiv. Mögliche weitere Einleitungen, um einen guten Obersatz zu formulieren sind: Zu untersuchen ist, ... oder  Zweifelhaft ist, ... ."
  }
  if (leftPaneState.majorClaimCount + 3 < leftPaneState.conclusionCount) {
    return currentLanguage === "en"
      ? "Several conclusions are not opened by major claims. Formulate some corresponding major claims in the conjunctive."
      : "Einige Konklusionen werden nicht durch einen Obersatz eröffnet. Formuliere entsprechende Obersätze zum Beispiel im Konjunktiv. Mögliche weitere Einleitungen, um einen guten Obersatz zu formulieren sind: Fraglich ist, ... oder Zu prüfen gilt es... ."
  }
  return currentLanguage === "en" ? "No errors!" : "Keine Fehler!"
}


export const recommendationForDefinition = (leftPaneState, currentLanguage) => {
  if (leftPaneState.majorClaimCount > leftPaneState.definitionCount) {
    return currentLanguage === "en"
      ? "Check that you have explained all major claims by a definition. This is important so that all the facts raised in the major claim can be explained. Note that some subordinate clauses do not need an additional definition, so check carefully if you need to add another definition."
      : "Überprüfe, ob du alle Obersätze durch eine Definition erläutert hast. Dies ist wichtig, damit alle im Obersatz aufgeworfenen Gesichtspunkte rechtlich näher erläutert werden können. Hinweis: Einige untergeordnete Obersätze benötigen keine zusätzliche Definition, prüfe daher sorgfältig, ob du weitere Definition hinzufügen musst."
  }
  return currentLanguage === "en" ? "No errors!" : "Keine Fehler!"
}

export const recommendationForConclusion = (leftPaneState, currentLanguage) => {
  if (leftPaneState.majorClaimCount === leftPaneState.conclusionCount + 1) {
    return currentLanguage === "en"
      ? "One major claim is not closed by a conclusion. Think about which major claims have not yet been brought to a conclusion and formulate the corresponding conclusion. Also make sure that all conclusions have been weighed in a subsumption."
      : "Ein Obersatz wird nicht durch eine Konklusion beantwortet. Überlege welche Obersätze noch nicht zu einem Abschluss gebracht wurden und formuliere die entsprechende Konklusion." +
          "\n"
  }
  if (leftPaneState.majorClaimCount === leftPaneState.conclusionCount + 2) {
    return currentLanguage === "en"
      ? "Two major claims are not closed by conclusions. Think about which major claims have not yet been brought to a conclusion and formulate the corresponding conclusion. Also make sure that all conclusions have been weighed in a subsumption."
      : "Zwei Obersätze sind nicht durch Konklusionen beantwortet. Überlege welche Obersätze noch nicht zu einem Abschluss gebracht wurden und formuliere die entsprechenden Konklusionen." +
          "\n"
  }
  if (leftPaneState.majorClaimCount === leftPaneState.conclusionCount + 3) {
    return currentLanguage === "en"
      ? "Three major claims are not closed by conclusions. Think about which major claims have not yet been brought to a conclusion and formulate the corresponding conclusion. Also make sure that all conclusions have been weighed in a subsumption."
      : "Drei Obersätze sind nicht durch Konklusionen beantwortet. Überlege welche Obersätze noch nicht zu einem Abschluss gebracht wurden und formuliere die entsprechenden Konklusionen." +
          "\n"
  }
  if (leftPaneState.majorClaimCount > leftPaneState.conclusionCount + 3) {
    return currentLanguage === "en"
      ? "Several major claims are not closed by conclusions. Think about which major claims have not yet been brought to a conclusion and formulate the corresponding conclusion. Also make sure that all conclusions have been weighed in a subsumption."
      : "Einige Obersätze sind nicht durch Konklusionen beantwortet. Überlege welche Obersätze noch nicht zu einem Abschluss gebracht wurden und formuliere die entsprechenden Konklusionen." +
          "\n"
  }
  return currentLanguage === "en" ? "No errors!" : "Keine Fehler!"
}

export const recommendationForLegalClaim = (numberOfErrors, currentLanguage) => {
  if (numberOfErrors === 1) {
    return currentLanguage === "en"
      ? "A legal claim is not backed by a premise. Try to derive one or more premises for the legal claim from the case study. If you cannot find supporting premises in the case studies, your legal claim may not be admissible."
      : "Eine Schlussfolgerung wird nicht durch eine oder mehrere Tatsachen gestützt. Versuche, eine oder mehrere Tatschaen aus dem Sachverhalt abzuleiten. Wenn du in den Fallstudien keine unterstützenden Tatsachen finden kannst, ist die Schlussfolgerung möglicherweise nicht zulässig."
  } else if (numberOfErrors === 2) {
    return currentLanguage === "en"
      ? "Two legal claims are not backed by premises. Try to derive one or more premises for the legal claims from the case study. If you cannot find supporting premises in the case studies, your legal claims may not be admissible."
      : "Zwei Schlussfolgerungen werden nicht durch eine oder mehrere Tatschaen gestützt. Versuche, eine oder mehrere Tatsachen für den Rechtsanspruch aus der Fallstudie abzuleiten. Wenn du in den Fallstudien keine unterstützenden Tatsachen finden kannst, ist die Schlussfolgerung möglicherweise nicht zulässig."
  } else if (numberOfErrors === 3) {
    return currentLanguage === "en"
      ? "Three legal claims are not backed by premises. Try to derive one or more premises for the legal claims from the case study. If you cannot find supporting premises in the case studies, your legal claims may not be admissible."
      : "Drei Schlussfolgerungen werden nicht durch eine oder mehrere Tatschaen gestützt. Versuche, eine oder mehrere Tatsachen für den Rechtsanspruch aus der Fallstudie abzuleiten. Wenn du in den Fallstudien keine unterstützenden Tatsachen finden kannst, ist die Schlussfolgerung möglicherweise nicht zulässig."
  } else if (numberOfErrors > 3) {
    return currentLanguage === "en"
      ? "Several legal claims are not backed by premises. Try to derive one or more premises for the legal claims from the case study. If you cannot find supporting premises in the case studies, your legal claims may not be admissible."
      : "Einige Schlussfolgerungen werden nicht durch eine oder mehrere Tatschaen gestützt. Versuche, eine oder mehrere Tatsachen für den Rechtsanspruch aus der Fallstudie abzuleiten. Wenn du in den Fallstudien keine unterstützenden Tatsachen finden kannst, ist die Schlussfolgerung möglicherweise nicht zulässig."
  }
  return currentLanguage === "en" ? "No errors!" : "Keine Fehler!"
}

export const recommendationForPremise = (numberOfErrors, currentLanguage) => {
  if (numberOfErrors === 1) {
    return currentLanguage === "en"
      ? "A premise does not appear to have a connection to a legal claim. Try to draw a legal claim from the premise or consider whether the single premise that does not cover a legal claim is necessary."
      : "Eine Tatsache hat keinen Bezug zu einer Schlussfolgerung. Versuche aus der Tatsachen eine Schlussfolgerung abzuleiten oder überlege, ob die Prämisse, die keine Schlussfolgerung unterstützt, notwendig ist." +
          "\n"
  } else if (numberOfErrors === 2) {
    return currentLanguage === "en"
      ? "Two premises do not appear to have a connection to one or more legal claim. Try to draw a legal claim from the premises or consider whether the two premises are necessary."
      : "Zwei Tatsachen haben keinen Bezug zu einer oder mehrerer Schlussfolgerungen zu haben. Versuche aus den Tatschen eine oder mehrere Schlussfolgerungen abzuleiten oder überlege, ob die Tatsache, die keine Schlussfolgerung (Claim) unterstützt, notwendig ist." +
          "\n"
  } else if (numberOfErrors === 3) {
    return currentLanguage === "en"
      ? "Three premises do not appear to have a connection to one or more legal claim. Try to draw a legal claim from the premises or consider whether the three premises are necessary."
      : "Drei Tatsachen haben keinen Bezug zu einer oder mehrerer Schlussfolgerungen. Versuche aus den Tatsachen eine oder mehrere Schlussfolgerungen abzuleiten oder überlege, ob die Tatsache, die keine Schlussfolgerung unterstützt, notwendig ist." +
          "\n"
  } else if (numberOfErrors > 3) {
    return currentLanguage === "en"
      ? "Several premises do not appear to have a connection to one or more legal claim. Try to draw a legal claim from the premises or consider whether the premises are necessary."
      : "Einige Tatsachen haben keinen Bezug zu einer oder mehrerer Schlussfolgerungen. Versuche aus den Tatsachen eine oder mehrere Schlussfolgerungen abzuleiten oder überlege, ob die Tatsache, die keine Schlussfolgerung unterstützt, notwendig ist." +
          "\n"
  }
  return currentLanguage === "en" ? "No errors!" : "Keine Fehler!"
}
