import * as React from "react"
import PaneWrapper from "./PaneWrapper"
import { NOTE_ON_APPRAISAL_STYLE_TEXT } from "../../constants/lang"
import PaneText from "./PaneText"
import Markdown from "react-markdown"

export default function NoteOnAppraisalStylePane({ open, setOpen }) {
  return (
    <PaneWrapper
      title="Hinweise zum Gutachtenstil"
      open={open}
      setOpen={setOpen}>
        <Markdown className="text-sm markdown-styling">{NOTE_ON_APPRAISAL_STYLE_TEXT.trim()}</Markdown>
    </PaneWrapper>
  )
}
