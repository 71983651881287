import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"

export default function LinearDeterminate({ progress }) {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <LinearProgress variant="determinate" sx={{height: 30}} value={progress} /> */}
      <div style={{ position: "relative" }}>
        <LinearProgress
          sx={{ height: 30 }}
          variant="determinate"
          className="rounded"
          value={progress}
        />
        <div
          style={{
            position: "absolute",
            color: "white",
            top: "6.5%",
            left: "12%",
            transform: "translateX(-50%)",
          }}>
          {progress + "%"}
        </div>
      </div>
    </Box>
  )
}
