import "./main.css"
import React, { useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import FeedbackPane from "../../components/panes/FeedbackPane"
import MiddleMainPane from "./MiddleMainPane"
import ImprovementChecklistLeftPane from "../../components/panes/ImprovementChecklistLeftPane"
import { useRecoilState } from "recoil"
import { dpGroupState } from "../../state/recoil"
import NoteOnSubsumptionLeftPane from "../../components/panes/NoteOnSubsumptionLeftPane"
import MyPlanPane from "../../components/panes/MyPlanPane"
import AppWrapper from "../../components/AppWrapper"
import LogoWrapper from "../../components/LogoWrapper"
import NoteOnAppraisalStylePane from "../../components/panes/NoteOnAppraisalStyleLeftPane"

// const apiUrl = process.env.REACT_APP_API_URL

export default function Main({ version }) {
  const [dpGroup] = useRecoilState(dpGroupState)
  const [showFeedback, setShowFeedback] = useState(true)

  const [noteOnSubsumptionPaneOpen, setNoteOnSubsumptionPaneOpen] =
    useState(false)
  const [noteOnAppraisalStylePaneOpen, setNoteOnAppraisalStylePaneOpen] =
    useState(true)
  const [improvementChecklistPaneOpen, setImprovementChecklistPaneOpen] =
    useState(true)
  const [feedbackPaneOpen, setFeedbackPaneOpen] = useState(false)
  const [myPlanPaneOpen, setMyPlanPaneOpen] = useState(true)

  const [feedbackResultState, setFeedbackResultState] = useState({
    feedbackRetrieved: false,
    leftPaneState: {
      majorClaimCount: 0,
      majorClaimErrors: 0,
      conclusionCount: 0,
      conclusionErrors: 0,
      definitionCount: 0,
      definitionErrors: 0,
      subsumptionCount: 0,
      subsumptionErrors: 0,
      legalClaimCount: 0,
      legalClaimErrors: 0,
      premiseCount: 0,
      premiseErrors: 0,
    },
    rightPanelState: {
      majorClaimPercentage: 0,
      conclusionPercentage: 0,
      definitionPercentage: 0,
      subsumptionPercentage: 0,
      legalClaimPercentage: 0,
      premisePercentage: 0,
    },
  })

  const hasLeftPaneSubsumptionNote =
    dpGroup === 1 || dpGroup === 3 || dpGroup === 5
  const hasLeftPaneCheckList =
    dpGroup === 2 || dpGroup === 4 || dpGroup === 6 || dpGroup === 7

  const hasRightPaneFeedback =
    dpGroup === 3 || dpGroup === 5 || dpGroup === 6 || dpGroup === 7

  const hasRightPaneMyPlan =
    dpGroup === 1 || dpGroup === 4 || dpGroup === 5 || dpGroup === 7
  const hasLeftPaneMyPlan = false

  useEffect(() => {
    if (!feedbackPaneOpen && feedbackResultState.feedbackRetrieved) {
      setFeedbackPaneOpen(true)
    }
  }, [feedbackResultState.feedbackRetrieved])

  return (
    <AppWrapper>
      <Grid container spacing={2}>
        <Grid item xs={3} xm={3} xl={3}>
          <div className="flex flex-col gap-2">
            <LogoWrapper />
            {hasLeftPaneSubsumptionNote && (
              <>
                <NoteOnAppraisalStylePane
                  open={noteOnAppraisalStylePaneOpen}
                  setOpen={setNoteOnAppraisalStylePaneOpen}
                />
                <NoteOnSubsumptionLeftPane
                  open={noteOnSubsumptionPaneOpen}
                  setOpen={setNoteOnSubsumptionPaneOpen}
                />
              </>
            )}
            {hasLeftPaneCheckList && (
              <ImprovementChecklistLeftPane
                feedbackResultState={feedbackResultState}
                version={version}
                open={improvementChecklistPaneOpen}
                setOpen={setImprovementChecklistPaneOpen}
              />
            )}
            {hasLeftPaneMyPlan && (
              <MyPlanPane
                wrapperClassName=""
                open={myPlanPaneOpen}
                setOpen={setMyPlanPaneOpen}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={6} xm={6} xl={6}>
          <MiddleMainPane
            feedbackResultState={feedbackResultState}
            setFeedbackResultState={setFeedbackResultState}
            version={version}
          />
        </Grid>
        <Grid item xs={3} xm={3} xl={3}>
          <div className="flex flex-col gap-2">
            {hasRightPaneFeedback && showFeedback && (
              <FeedbackPane
                feedbackResultState={feedbackResultState}
                open={feedbackPaneOpen}
                setOpen={setFeedbackPaneOpen}
              />
            )}
            {hasRightPaneMyPlan && (
              <MyPlanPane open={myPlanPaneOpen} setOpen={setMyPlanPaneOpen} />
            )}
          </div>
        </Grid>
      </Grid>
    </AppWrapper>
  )
}
