import { capitalize } from "../lib/utils"

export const obersatzColor = "lightsalmon"
export const konklusionColor = "pink"
export const subsumptionColor = "cyan"
export const definitionColor = "violet"
export const ueberschriftColor = "gray"
export const separatorColor = "lightgray"

export const LEGAL_TYPE_SEPARATOR = "trenner"
export const LEGAL_TYPE_UEBERSCHRIFT = "ueberschrift"
export const LEGAL_TYPE_OBERSATZ = "obersatz"
export const LEGAL_TYPE_KONKLUSION = "konklusion"
export const LEGAL_TYPE_SUBSUMPTION = "subsumtion"
export const LEGAL_TYPE_DEFINITION = "definition"
export const LEGAL_TYPE_CLAIM = "claim"
export const LEGAL_TYPE_PREM = "prämisse"

export const EXPLANATION_UEBERSCHRIFT_TITLE = "Was ist eine Überschrift?"
export const EXPLANATION_UEBERSCHRIFT_TEXT =
  "Bevor du einen neuen Obersatz öffnest, nutze erst eine Überschrift (diese setzt die Nummerierung für die Komponenten).  Du kannst die Überschrift verwenden, um deine Falllösung zusätzlich zu strukturieren. Hinweis: Die momentane Version von LegalWriter nutzt noch kein alphanumerisches Gliederungssystem. Möchtest du dieses trotzdem anwenden, kannst du dies in den Komponenten bzw. in den Überschriften manuell eingeben."
export const EXPLANATION_OBERSATZ_TITLE = "Was ist ein Obersatz?"
export const EXPLANATION_OBERSATZ_TEXT =
  "Der Obersatz bildet die Fragestellung des jeweiligen Prüfungsgegenstandes (üblicherweise das zu prüfende Tatbestandsmerkmal), der im Folgenden untersucht wird. Obersätze sind auf allen Ebenen des Gutachtens zu bilden, beginnend bei der Fallfrage, über die Prüfung einzelner Normen, bis hin zu den darin enthaltenden Tatbestandsmerkmalen. Häufig werden Obersätze im Konjunktiv formuliert."
export const EXPLANATION_KONKLUSION_TITLE = "Was ist eine Konklusion?"
export const EXPLANATION_KONKLUSION_TEXT =
  "Die Konklusion ist die Antwort auf den gestellten Obersatz. Somit kommt die Falllösung hier zu einem Endergebnis. Die im Obersatz formulierte Frage wird beantwortet. Die Konklusion wird oftmals im Indikativ verfasst. Begründungen sind hier fehl am Platz, diese gehören nur in die Definition bzw. Subsumtion."
export const EXPLANATION_SUBSUMPTION_TITLE = "Was ist eine Subsumtion?"
export const EXPLANATION_SUBSUMPTION_TEXT =
  "In der Subsumtion wird geprüft, inwieweit die im Sachverhalt geschilderten konkrete Tatsachen mit den abstrakten Voraussetzungen der Definition übereinstimmen. Hier wird der Sachverhalt gegenüber den Voraussetzungen aus den Definitionen und den Tatbestandsmerkmalen argumentativ ab gewägt. In manchen Fällen ist es sinnvoll, Schlussfolgerungen zu bilden (Zwischenergebnisse)."
export const EXPLANATION_DEFINITION_TITLE = "Was ist eine Definition?"
export const EXPLANATION_DEFINITION_TEXT =
  "Die Definition benennt die abstrakten Voraussetzungen des im Obersatz aufgeworfenen Prüfungsgegenstands. Sind diese Voraussetzungen unklar, so müssen sie durch Auslegung ermittelt werden. Beachten Sie, dass es sich bei den von der Literatur und Rechtsprechung entwickelten Theorien und Meinungen um standardisierte Auslegung handelt."
export const EXPLANATION_CLAIM_TITLE = "Was ist eine Schlussfolgerung (Zwischenfazit)?"
export const EXPLANATION_CLAIM_TEXT =
  "Schlussfolgerungen werden durch konkrete Tatsachen gestützt. Es kann sinnvoll sein eine Schlussfolgerung (Zwischenergebnis) zu bilden, wenn viele Definitionsvoraussetzungen zu prüfen sind."
export const EXPLANATION_PREM_TITLE = "Was ist eine konkrete Tatschache?"
export const EXPLANATION_PREM_TEXT =
  "Die konkreten Tatsachen werden aus dem vorliegenden Fall, einer logischen Schlussfolgerung oder nach dem Allgemeinwissen abgeleitet. Verwende konkrete Tatsachen, um die Definitionsmerkmale/-voraussetzungen zu prüfen."

export function getExplanationTitleForType(type) {
  switch (type) {
    case LEGAL_TYPE_CLAIM:
      return EXPLANATION_CLAIM_TITLE
    case LEGAL_TYPE_PREM:
      return EXPLANATION_PREM_TITLE
    case LEGAL_TYPE_SUBSUMPTION:
      return EXPLANATION_SUBSUMPTION_TITLE
    case LEGAL_TYPE_OBERSATZ:
      return EXPLANATION_OBERSATZ_TITLE
    case LEGAL_TYPE_DEFINITION:
      return EXPLANATION_DEFINITION_TITLE
    case LEGAL_TYPE_KONKLUSION:
      return EXPLANATION_KONKLUSION_TITLE
    case LEGAL_TYPE_UEBERSCHRIFT:
      return EXPLANATION_UEBERSCHRIFT_TITLE
    default:
      return "Kein Titel vorhanden"
  }
}

export function mapTypeToOldId(type) {
  switch (type) {
    case LEGAL_TYPE_CLAIM:
      return 5
    case LEGAL_TYPE_PREM:
      return 6
    case LEGAL_TYPE_SUBSUMPTION:
      return 4
    case LEGAL_TYPE_OBERSATZ:
      return 1
    case LEGAL_TYPE_DEFINITION:
      return 3
    case LEGAL_TYPE_KONKLUSION:
      return 2
    default:
      return -1
  }
}

export function getExplanationTextForType(type) {
  switch (type) {
    case LEGAL_TYPE_CLAIM:
      return EXPLANATION_CLAIM_TEXT
    case LEGAL_TYPE_PREM:
      return EXPLANATION_PREM_TEXT
    case LEGAL_TYPE_SUBSUMPTION:
      return EXPLANATION_SUBSUMPTION_TEXT
    case LEGAL_TYPE_OBERSATZ:
      return EXPLANATION_OBERSATZ_TEXT
    case LEGAL_TYPE_DEFINITION:
      return EXPLANATION_DEFINITION_TEXT
    case LEGAL_TYPE_KONKLUSION:
      return EXPLANATION_KONKLUSION_TEXT
    case LEGAL_TYPE_UEBERSCHRIFT:
      return EXPLANATION_UEBERSCHRIFT_TEXT
    default:
      return "Kein Text vorhanden"
  }
}

export function getLabelForType(type) {
  switch (type) {
    case LEGAL_TYPE_UEBERSCHRIFT:
      return "Überschrift"
    case LEGAL_TYPE_CLAIM:
      return "Schlussfolgerung"
    case LEGAL_TYPE_KONKLUSION:
      return "Konklusion (Ergebnis)"
    case LEGAL_TYPE_PREM:
      return "Konkrete Tatsache"
    default:
      return capitalize(type)
  }
}

// aktuell gibt es auch noch die Klassen, die separat in der main.css gemappt werden müssen
export function getColorForType(type) {
  switch (type) {
    case "ueberschrift":
      return ueberschriftColor
    case "obersatz":
      return obersatzColor
    case "konklusion":
      return konklusionColor
    case "subsumtion":
      return subsumptionColor
    case "definition":
      return definitionColor
    case LEGAL_TYPE_CLAIM:
      return "lightgreen"
    case LEGAL_TYPE_PREM:
      return "gold"
    case LEGAL_TYPE_SEPARATOR:
      return separatorColor
    default:
      return "gray"
  }
}
