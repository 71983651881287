const isProduction = process.env.NODE_ENV === "production" || process.env.REACT_APP_TEST_PROD === "1"
//const BASE_URL = isProduction ? process.env.REACT_APP_BASE_API_URL : "https://394781834185691784.wise-kassel.de"
const BASE_URL = isProduction ? process.env.REACT_APP_BASE_API_URL : "http://localhost:5005"

export const apiUrl = `${BASE_URL}/predict`
export const textIdUrl = `${BASE_URL}/getLatestText`
export const userTextsUrl = `${BASE_URL}/getUserTexts`
export const submitUrl = `${BASE_URL}/submit`
// export const loginUrl = `${BASE_URL}/getTexts`
export const loginUrl = `${BASE_URL}/login`
export const registerUrl = `${BASE_URL}/register`
