import React from "react"
import PaneWrapper from "./PaneWrapper"
import PlanItemContainer from "../../pages/plan/PlanItemContainer"
import { useRecoilState } from "recoil"
import { planItemsState } from "../../state/recoil"
import PaneText from "./PaneText"
import { NO_PLAN_MADE } from "../../constants/lang"

export default function MyPlanPane({ wrapperClassName, open, setOpen }) {
  const [planItems] = useRecoilState(planItemsState)

  return (
    <PaneWrapper title="Meine Skizze" className={wrapperClassName} open={open} setOpen={setOpen}>
      <div className="">
        {planItems.length === 0 && <PaneText>{NO_PLAN_MADE}</PaneText>}
        {planItems.map((pi, idx) => (
          <div key={`plan-pi-${idx}`} className="flex gap-1 mb-2">
            <PlanItemContainer
              type={pi.type}
              textValue={pi.text}
              counterValue={pi.manualCounter}
              idx={idx}
              small
            />
          </div>
        ))}
      </div>
    </PaneWrapper>
  )
}
