import * as React from "react"
import Divider from "@mui/material/Divider"
import { useRecoilState } from "recoil"
import { languageState } from "../../state/recoil"
import PlanItemButton, { PlanItemButtonWithTooltip } from "./PlanItemButton"
import {
  LEGAL_TYPE_DEFINITION,
  LEGAL_TYPE_KONKLUSION,
  LEGAL_TYPE_OBERSATZ,
  LEGAL_TYPE_SEPARATOR,
  LEGAL_TYPE_SUBSUMPTION,
  LEGAL_TYPE_UEBERSCHRIFT,
  definitionColor,
  getExplanationTextForType,
  getExplanationTitleForType,
  getLabelForType,
  konklusionColor,
  obersatzColor,
  separatorColor,
  subsumptionColor,
  ueberschriftColor,
} from "../../constants/types"
import PaneWrapper from "../../components/panes/PaneWrapper"
import PaneText from "../../components/panes/PaneText"

export default function LeftPlanPane({
  state,
  version,
  addNewPlanItem,
  open,
  setOpen,
}) {
  const [currentLanguage] = useRecoilState(languageState)

  return (
    <PaneWrapper title="Strukturierungshilfe" open={open} setOpen={setOpen}>
      <PaneText>
        {currentLanguage === "en"
          ? "Before you begin writing your case solution, please prepare a brief planning of your case solution in appraisal style."
          : "Bevor du mit dem Schreiben deiner Falllösung beginnst, fertige bitte eine kurze Planung deiner Falllösung im Gutachtenstil an."}
      </PaneText>
      <div className="plan-item-button-container">
        <PlanItemButtonWithTooltip
          id={LEGAL_TYPE_UEBERSCHRIFT}
          backgroundColor={ueberschriftColor}
          addNewPlanItem={addNewPlanItem}
          interactive
          className="cursor-pointer"
        />
        <PlanItemButtonWithTooltip
          id={LEGAL_TYPE_OBERSATZ}
          backgroundColor={obersatzColor}
          addNewPlanItem={addNewPlanItem}
          interactive
          className="cursor-pointer"
        />
        <PlanItemButtonWithTooltip
          id={LEGAL_TYPE_DEFINITION}
          backgroundColor={definitionColor}
          addNewPlanItem={addNewPlanItem}
          className="cursor-pointer"
          interactive
        />
        <PlanItemButtonWithTooltip
          id={LEGAL_TYPE_SUBSUMPTION}
          backgroundColor={subsumptionColor}
          addNewPlanItem={addNewPlanItem}
          className="cursor-pointer"
          interactive
        />
        <PlanItemButtonWithTooltip
          id={LEGAL_TYPE_KONKLUSION}
          backgroundColor={konklusionColor}
          addNewPlanItem={addNewPlanItem}
          className="cursor-pointer"
          interactive
        />
      </div>
    </PaneWrapper>
  )
}
