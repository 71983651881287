import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { useRecoilState } from "recoil"
import { languageState } from "../state/recoil"
import { forwardRef } from "react"

export function TooltipTitle({ children }) {
  return <h2 className="text-lg">{children}</h2>
}

export const TooltipMore = forwardRef(function (props, ref) {
  const [currentLanguage] = useRecoilState(languageState)

  return (
    <span
      ref={ref}
      {...props}
      className="ml-1"
      style={{ color: "blue", textDecoration: "underline" }}>
      {currentLanguage === "en" ? "More" : "Mehr"}
    </span>
  )
})

export function TooltipText({ children }) {
  return <p className="text-base font-normal">{children}</p>
}

export function HtmlTooltipSimple({ className, ...props }) {
  return <Tooltip {...props} classes={{ popper: className }} />
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: "#f5f5f9",
    // color: "rgba(0, 0, 0, 0.87)",
    // fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
  },
}))

export default HtmlTooltip
