import * as React from "react"
import Grid from "@mui/material/Grid"
import Backdrop from "@mui/material/Backdrop"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import { CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"
import DPGroupSetter from "../components/DPGroupSetter"
import { loginUrl, registerUrl } from "../constants/env"
import { useRecoilState } from "recoil"
import { dpGroupState } from "../state/recoil"
import { TOKEN_LOCAL_STORAGE_ID } from "../constants/api"

const setLocalStorageWithSelectedOptions = (username, token) => {
  // let fallstudiesSelect = document.getElementById('fallstudies');
  // let fallstudiesValue = fallstudiesSelect.options[fallstudiesSelect.selectedIndex].value;
  // localStorage.setItem('caseStudiesValue', fallstudiesValue);
  localStorage.setItem("username", username)
  localStorage.setItem(TOKEN_LOCAL_STORAGE_ID, token)
}

const register = (
  dp,
  setCurrentPage,
  setAgreeDialogOpen,
  setLoadingIndicatorOpen
) => {
  setAgreeDialogOpen(false)
  const username = document.getElementById("username").value
  const key = document.getElementById("key").value
  setLoadingIndicatorOpen(true)

  fetch(registerUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      username: username,
      key: key,
      dp,
    }),
  })
    .then(response => {
      response
        .json()
        .then(data => {
          setLoadingIndicatorOpen(false)
          if (data["success"] === "true") {
            alert(
              "Die Registrierung war erfolgreich! Sie können sich jetzt bei Legal Writer anmelden."
            )
            setCurrentPage("login")
          } else {
            alert("Fehler: " + data["error"])
          }
        })
        .catch(err => {
          console.log(err)
          setLoadingIndicatorOpen(false)
          alert("Fehler")
        })
    })
    .catch(err => {
      console.log(err)
      setLoadingIndicatorOpen(false)
      alert("Fehler")
    })
}

const login = (setAgreeDialogOpen, setLoadingIndicatorOpen, navigate) => {
  setAgreeDialogOpen(false)
  const username = document.getElementById("username").value
  const key = document.getElementById("key").value

  setLoadingIndicatorOpen(true)
  fetch(loginUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      username: username,
      key: key,
    }),
  })
    .then(response => {
      response
        .json()
        .then(data => {
          setLoadingIndicatorOpen(false)
          if (data["success"] === "true") {
            const token = data["token"]

            setLocalStorageWithSelectedOptions(username, token)
            navigate("/courses")
          } else {
            setLoadingIndicatorOpen(false)
            alert("Fehler: " + data["error"])
          }
        })
        .catch(err => {
          console.log(err)
          setLoadingIndicatorOpen(false)
          alert("Fehler")
        })
    })
    .catch(err => {
      console.log(err)
      setLoadingIndicatorOpen(false)
      alert("Fehler")
    })
}

// rrggbb(167, 205, 171)

function LoginPage() {
  const [isAgreeDialogOpen, setAgreeDialogOpen] = React.useState(false)
  const [isLoadingIndicatorOpen, setLoadingIndicatorOpen] =
    React.useState(false)
  const [currentPage, setCurrentPage] = React.useState("login") // login - register
  const [dpGroup] = useRecoilState(dpGroupState)
  const navigate = useNavigate()

  return (
    <>
      <div className="login-page-container">
        <DPGroupSetter />
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#333",
              padding: "8%",
              paddingBottom: "8%",
              borderRadius: 20,
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <h1 style={{ fontSize: "300%", color: "rgb(142, 187, 222)" }}>
                Willkommen bei Legal Writer
              </h1>{" "}
              <br />
              {false ? (
                <></>
              ) : (
                <>
                  <span style={{ color: "rgb(142, 187, 222)", fontSize: 20 }}>
                    Universität E-Mail
                  </span>{" "}
                  <br />
                  <input
                    type="text"
                    id="username"
                    name="username"
                    style={{
                      width: 490,
                      height: 30,
                      backgroundColor: "rgb(142, 187, 222)",
                      borderRadius: 5,
                      border: "none",
                      resize: "none",
                      outline: "none",
                      fontSize: "110%",
                      textAlign: "center",
                      padding: 5,
                    }}
                  />{" "}
                  <br />
                  <span style={{ color: "rgb(142, 187, 222)", fontSize: 20 }}>
                    Passwort
                  </span>{" "}
                  <br />
                  <input
                    type="password"
                    id="key"
                    name="key"
                    placeholder=""
                    style={{
                      width: 490,
                      height: 30,
                      backgroundColor: "rgb(142, 187, 222)",
                      borderRadius: 5,
                      border: "none",
                      resize: "none",
                      outline: "none",
                      fontSize: "110%",
                      textAlign: "center",
                      padding: 5,
                    }}
                  />
                  {currentPage === "login" && (
                    <>
                      <br />
                    </>
                  )}
                  <br /> <br /> <br />
                  {currentPage === "login" ? (
                    <button
                      style={{
                        width: 150,
                        height: 50,
                        backgroundColor: "rgb(66, 182, 162)",
                        borderRadius: 10,
                        border: "none",
                        resize: "none",
                        outline: "none",
                        fontSize: "120%",
                        textAlign: "center",
                        padding: 5,
                        cursor: "pointer",
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        login(
                          setAgreeDialogOpen,
                          setLoadingIndicatorOpen,
                          navigate
                        )
                      }}>
                      Anmeldung
                    </button>
                  ) : (
                    <button
                      style={{
                        width: 150,
                        height: 50,
                        backgroundColor: "rgb(66, 182, 162)",
                        borderRadius: 10,
                        border: "none",
                        resize: "none",
                        outline: "none",
                        fontSize: "120%",
                        textAlign: "center",
                        padding: 5,
                        cursor: "pointer",
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        setAgreeDialogOpen(true)
                      }}>
                      Register
                    </button>
                  )}
                  <br /> <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {currentPage === "login" ? (
                      <span
                        style={{
                          textAlign: "center",
                          color: "rgb(142, 187, 222)",
                          fontSize: "125%",
                          fontWeight: "bold",
                        }}>
                        <br /> Oder{" "}
                        <span
                          style={{
                            fontStyle: "italic",
                            color: "rgb(66, 182, 162)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCurrentPage("register")
                          }}>
                          registrieren
                        </span>{" "}
                        Sie sich, wenn Sie noch kein Konto haben <br />
                      </span>
                    ) : (
                      <span
                        style={{
                          textAlign: "center",
                          color: "rgb(142, 187, 222)",
                          fontSize: "125%",
                          fontWeight: "bold",
                        }}>
                        Haben Sie bereits ein Konto mit Ihrer E-Mail im System
                        angelegt? <br /> <br />
                        <span
                          style={{
                            fontStyle: "italic",
                            color: "rgb(66, 182, 162)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCurrentPage("login")
                          }}>
                          Anmelden
                        </span>
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoadingIndicatorOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={isAgreeDialogOpen}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          Zustimmung zum Fortfahren
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <>
              <h3>Datenschutzinformation zum Projekt Legal Writer</h3>
              <h4>Verarbeitungszweck der erhobenen Daten</h4>
              <p>
                Im Rahmen der folgenden Anwendung und Umfrage stellen wir Ihnen
                Fragen zum Thema IT-Nutzung in der Lehre. Wir verfolgen das
                Ziel, die Wahrnehmung und das Erleben aus der Sicht der
                Teilnehmenden zu erfassen und somit eine Steigerung des
                Erkenntnisgewinns hinsichtlich der Entwicklung von Lernsystemen
                zu erzielen. Das Forschungsprojekt wird vom Institut
                Wirtschaftsinformatik und Systementwicklung der Universität
                Kassel durchgeführt. Im Rahmen der Umfrage werden folgende
                personenbezogenen Daten erhoben: Alter, Geschlecht,
                Nationalität, Ausbildungsstand, juristischer und technischer
                Wissensstand sowie technische Affinitäten. Diese Daten werden
                von uns ausschließlich zur wissenschaftlichen Auswertung im
                Rahmen der Umfrage verwendet.{" "}
              </p>
              <p>
                Innerhalb der Anwendung werden Ihre personenbezogenen Daten
                automatisiert verarbeitet. Der von Ihnen eingegebene Text wird
                von einer trainierten, selbstlernenden Software (Machine
                Learning) anhand der natürlichen Sprache ausgewertet (Natural
                Language Processing). So zieht die Anwendung Schlüsse aus dem
                Satzbau, sowie der im Einzelnen verwendeten Wörter. Zweck ist
                die Ermittlung, inwiefern Sie den juristischen Gutachtenstil in
                der jeweiligen Fallbearbeitung eingehalten haben und Ihre
                Argumentation einem logischen Aufbau folgt (z. B. die Abfolge
                von Prämissen und Konklusionen). Die resultierende Analyse
                fließt in keiner Weise in etwaige Bewertungen innerhalb Ihres
                Studiums ein, sondern dient lediglich der Optimierung Ihrer
                Anwendung des juristischen Gutachtenstils.{" "}
              </p>
              <p>
                Ihre eingegebenen Fallbearbeitungen können als Trainingsdaten
                zur Verbesserung der Anwendung verwendet werden. Dafür wird
                jeglicher Personenbezug der Daten entfernt. Zur Überprüfung der
                Anwendung sowie der wissenschaftlichen Evaluation der Anwendung,
                kann Ihre Bearbeitung des Sachverhalts auch von MitarbeiterInnen
                der Universität Kassel, solche die am Projekt Komp-HI beteiligt
                sind, eingesehen und bewertet werden. Diese Bewertung erfolgt
                pseudonymisiert und streng vertraulich. Die Bewertung wird
                ausschließlich zu wissenschaftlichen Zwecken vorgenommen und
                fließt in keiner Weise in etwaige Bewertungen innerhalb ihres
                Studiums ein. Zur Evaluation der Anwendung können Ihre Daten mit
                Ihren Prüfungsergebnissen abgeglichen werden, wenn die Anwendung
                in universitären Veranstaltungen zum Einsatz kommt. Dieser
                Abgleich erfolgt pseudonymisiert, streng vertraulich und dient
                ausschließlich wissenschaftlichen Zwecken. Sowohl die
                Pseudonymisierung als auch der Abgleich und die Auswertung
                werden von Forschenden des Projekts Komp-HI oder anderen
                neutralen, dritten Personen vorgenommen.
              </p>
              <p>
                Im Zuge der Anmeldung des Systems müssen Sie Ihre
                universitätsinterne E-Mail-Adresse angeben. Diese wird nur zu
                Versendung der erwähnten Umfragen verwendet. Sie erhalten
                keinerlei andere Umfragen oder Mails mit anderem Inhalt. Für die
                Funktionsfähigkeit der Anwendung und die Auswertung der
                Kontextinformationen sind keine personenbezogenen Daten
                erforderlich. Die Adressen werden automatisch nach Beendigung
                des Projekts gelöscht.
              </p>
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAgreeDialogOpen(false)
            }}>
            Reject
          </Button>
          <Button
            onClick={() => {
              window
                .open(process.env.PUBLIC_URL + "agreement.pdf", "_blank")
                .focus()
            }}>
            See Long Version
          </Button>
          <Button
            onClick={() => {
              register(
                dpGroup,
                setCurrentPage,
                setAgreeDialogOpen,
                setLoadingIndicatorOpen
              )
            }}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LoginPage
