import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./state/recoil"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import Main from "./pages/main"
import LoginPage from "./pages/Login"
import AdminPage from "./pages/Admin"
import CoursesPage from "./pages/courses"
import reportWebVitals from "./reportWebVitals"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { RecoilRoot } from "recoil"
import Plan from "./pages/plan"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import ParagraphsPage from "./pages/Paragraphs"
import SubmissionPage from "./pages/Submission"

const root = ReactDOM.createRoot(document.getElementById("root"))

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
})

const router = createBrowserRouter([
  { path: "/", element: <LoginPage admin={false} /> },
  { path: "/plan", element: <Plan /> },
  { path: "/admin", element: <AdminPage /> },
  { path: "/paragraphs", element: <ParagraphsPage /> },
  { path: "/submission", element: <SubmissionPage /> },
  { path: "/courses", element: <CoursesPage /> },
  { path: "/main", element: <Main version={1} /> },
])

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </RecoilRoot>
    </I18nextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
