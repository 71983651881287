import { apiUrl, submitUrl } from "../constants/env"
import { updateCounts, updateEditorWithJSONFromServer } from "./api"

function getEditorText() {
  const textContent = document.getElementById("text-editor").textContent
  if (textContent.includes("\n")) {
    return textContent
  }

  return document.getElementById("text-editor").innerText
}

export const retrieveFeedbackForText = async (
  dp,
  textId,
  setLoadingIndicatorOpen,
  feedbackResultState,
  setFeedbackResultState,
  setWordCount,
  keydowns,
  saveText = true,
) => {
  const username = localStorage.getItem("username")
  if (saveText && (!username || username === "")) {
    alert(
      "Fehler: Sie sollten sich erst in das System einloggen, um eine Rückmeldung zu erhalten."
    )
    return { textId }
  }

  setLoadingIndicatorOpen(true)

  const requestBody = {
    text: getEditorText(),
    username: username,
    dp,
    keydowns,
    saveText,
  }

  // console.log("sending text")
  // console.log(JSON.stringify(requestBody))

  if (textId && !saveText) {
    requestBody.id = textId
  }

  try {
    const resp = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(requestBody),
    })

    const data = await resp.json()

    keydowns = []
    setLoadingIndicatorOpen(false)
    let rightPanelState = updateEditorWithJSONFromServer(dp, data)
    let leftPaneState = updateCounts(data)


    const wordCount = document
      .getElementById("text-editor")
      .textContent.split(" ").length
    setWordCount(wordCount)

    setFeedbackResultState({
      ...feedbackResultState,
      feedbackRetrieved: true,
      textId: data.textId ?? "",
      leftPaneState: leftPaneState,
      rightPanelState: rightPanelState,
    })

    // updatePremiseClaimGraph(data, rightPanelState, setRightPanelState);
    // updatePercentages(data, rightPanelState, setRightPanelState);

    return {
      textId: data.id,
    }
  } catch (err) {
    console.log(err)
    setLoadingIndicatorOpen(false)
  }

  return { textId }
}

export const submitTextResult = async (
  dp,
  textId,
  setLoadingIndicatorOpen,
  keydowns,
  navigate
) => {
  const username = localStorage.getItem("username")
  if (!username || username === "") {
    alert(
      "Fehler: Sie sollten sich erst in das System einloggen, um eine Rückmeldung zu erhalten."
    )
    return
  }
  let result = window.confirm(
    "Sind Sie sicher, dass Sie Ihren Text einreichen wollen? Nach dem Absenden können Sie Ihren Text nicht mehr bearbeiten oder sehen."
  )
  if (!result) {
    return
  }
  setLoadingIndicatorOpen(true)

  const requestBody = {
    text: getEditorText(),
    keydowns: keydowns,
    username: username,
    dp,
  }

  if (textId) {
    requestBody.id = textId
  }

  try {
    const resp = await fetch(submitUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(requestBody),
    })
    const data = await resp.json()
    keydowns = []
    setLoadingIndicatorOpen(false)
    navigate("/courses")
  } catch (err) {
    console.log(err)
    setLoadingIndicatorOpen(false)
  }
}
