import * as React from "react"
import Divider from "@mui/material/Divider"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"
import { useRecoilState } from "recoil"
import { languageState, wordCountState } from "../../state/recoil"
import HtmlTooltip, {
  TooltipMore,
  TooltipText,
  TooltipTitle,
} from "../HtmlTooltip"
import {
  errorsForConclusion,
  errorsForDefinition,
  errorsForMajorClaim,
  recommendationForConclusion,
  recommendationForDefinition,
  recommendationForLegalClaim,
  recommendationForMajorClaim,
  recommendationForPremise,
} from "../../lib/recommendations"
import PaneWrapper from "./PaneWrapper"
import ComponentCircleExplanation from "../ComponentCircleExplanation"
import {
  LEGAL_TYPE_CLAIM,
  LEGAL_TYPE_DEFINITION,
  LEGAL_TYPE_KONKLUSION,
  LEGAL_TYPE_OBERSATZ,
  LEGAL_TYPE_PREM,
  LEGAL_TYPE_SUBSUMPTION,
} from "../../constants/types"
import PaneText from "./PaneText"
import classNames from "classnames"
import {
  CHECKLIST_EXPLANATION,
  CHECKLIST_EXPLANATION_TOOLTIP,
  FEEDBACK_COUNT_COMPONENT_LABEL,
  FEEDBACK_COUNT_ERROR_LABEL,
  SUBSUMPTION_SPLIT_EXPLANATION,
} from "../../constants/lang"

export default function ImprovementChecklistLeftPane({
  feedbackResultState,
  version,
  open,
  setOpen,
}) {
  const [currentLanguage, setCurrentLanguage] = useRecoilState(languageState)
  const [wordCount] = useRecoilState(wordCountState)

  const [isMajorClaimRecommendationOpen, setMajorClaimRecommendationOpen] =
    React.useState(false)
  const [isConclusionRecommendationOpen, setConclusionRecommendationOpen] =
    React.useState(false)
  const [isDefinitionRecommendationOpen, setDefinitionRecommendationOpen] =
    React.useState(false)
  const [isLegalClaimRecommendationOpen, setLegalClaimRecommendationOpen] =
    React.useState(false)
  const [isPremiseRecommendationOpen, setPremiseRecommendationOpen] =
    React.useState(false)
  const toggleMajorClaimRecommentationOpen = () => {
    setMajorClaimRecommendationOpen(!isMajorClaimRecommendationOpen)
  }
  const toggleConclusionRecommendationOpen = () => {
    setConclusionRecommendationOpen(!isConclusionRecommendationOpen)
  }
  const toggleDefinitionRecommendationOpen = () => {
    setDefinitionRecommendationOpen(!isDefinitionRecommendationOpen)
  }
  const toggleLegalClaimRecommendationOpen = () => {
    setLegalClaimRecommendationOpen(!isLegalClaimRecommendationOpen)
  }
  const togglePremiseRecommendationOpen = () => {
    setPremiseRecommendationOpen(!isPremiseRecommendationOpen)
  }

  return (
    <PaneWrapper title="Checklist" open={open} setOpen={setOpen}>
      <div className="flex flex-col items-end">
        {CHECKLIST_EXPLANATION.map((text, idx) => (
          <PaneText key={`explanation-${idx}`} small>
            {text}
          </PaneText>
        ))}
        <HtmlTooltip
          title={
            <>
              <TooltipTitle>Checklist</TooltipTitle>
              <TooltipText>{CHECKLIST_EXPLANATION_TOOLTIP}</TooltipText>
            </>
          }>
          <TooltipMore />
        </HtmlTooltip>
      </div>
      <div className="flex justify-center items-center">
        <h2 style={{ fontSize: 20 }} id="word-count">
          {wordCount} {currentLanguage === "en" ? " words" : " Wörter"}
        </h2>
      </div>
      <div className="my-2">
        <div className="flex justify-between items-between">
          <ComponentCircleExplanation type={LEGAL_TYPE_OBERSATZ} />
          <div>
            {isMajorClaimRecommendationOpen ? (
              <ArrowUpwardIcon onClick={toggleMajorClaimRecommentationOpen} />
            ) : (
              <ArrowDownwardIcon onClick={toggleMajorClaimRecommentationOpen} />
            )}
          </div>
        </div>
        <RecommendationWrapper open={isMajorClaimRecommendationOpen}>
          {recommendationForMajorClaim(
            feedbackResultState.leftPaneState,
            currentLanguage
          )}
        </RecommendationWrapper>
        <RecommendationFeedbackCountWrapper
          componentCount={feedbackResultState.leftPaneState.majorClaimCount}
          errorCount={errorsForMajorClaim(feedbackResultState.leftPaneState)}
        />
      </div>
      <Divider className="w-full border-gray-300" />
      <div className="my-2">
        <div className="flex justify-between items-between">
          <ComponentCircleExplanation type={LEGAL_TYPE_DEFINITION} />
          <div>
            {isDefinitionRecommendationOpen ? (
              <ArrowUpwardIcon onClick={toggleDefinitionRecommendationOpen} />
            ) : (
              <ArrowDownwardIcon onClick={toggleDefinitionRecommendationOpen} />
            )}
          </div>
        </div>
        <RecommendationWrapper open={isDefinitionRecommendationOpen}>
          {recommendationForDefinition(
            feedbackResultState.leftPaneState,
            currentLanguage
          )}
        </RecommendationWrapper>
        <RecommendationFeedbackCountWrapper
          componentCount={feedbackResultState.leftPaneState.definitionCount}
          errorCount={errorsForDefinition(feedbackResultState.leftPaneState)}
        />
      </div>
      <Divider className="w-full border-gray-300" />

      <div className="my-2">
        <ComponentCircleExplanation type={LEGAL_TYPE_SUBSUMPTION}>
          <TooltipMore />
        </ComponentCircleExplanation>
        <PaneText className="text-xs mt-1">
          {SUBSUMPTION_SPLIT_EXPLANATION}
        </PaneText>
      </div>
      <Divider className="w-full border-gray-300" />
      <div className="my-2 flex gap-2">
        <SubdirectoryArrowRightIcon className="text-gray-400" />
        <div className="w-full">
          <div className="flex justify-between items-between">
            <div className="text-sm">
              <ComponentCircleExplanation small type={LEGAL_TYPE_PREM} />
            </div>
            <div>
              {isPremiseRecommendationOpen ? (
                <ArrowUpwardIcon onClick={togglePremiseRecommendationOpen} />
              ) : (
                <ArrowDownwardIcon onClick={togglePremiseRecommendationOpen} />
              )}
            </div>
          </div>

          <RecommendationWrapper open={isPremiseRecommendationOpen}>
            {recommendationForPremise(
              feedbackResultState.leftPaneState.premiseErrors,
              currentLanguage
            )}
          </RecommendationWrapper>
          <RecommendationFeedbackCountWrapper
            componentCount={feedbackResultState.leftPaneState.premiseCount}
            errorCount={feedbackResultState.leftPaneState.premiseErrors}
          />
        </div>
      </div>
      <Divider className="w-full border-gray-300" />

      <div className="my-2 flex gap-2">
        <SubdirectoryArrowRightIcon className="text-gray-400" />
        <div className="w-full">
          <div className="flex justify-between items-between">
            <div className="text-sm">
              <ComponentCircleExplanation small type={LEGAL_TYPE_CLAIM} />
            </div>
            <div>
              {isLegalClaimRecommendationOpen ? (
                <ArrowUpwardIcon onClick={toggleLegalClaimRecommendationOpen} />
              ) : (
                <ArrowDownwardIcon
                  onClick={toggleLegalClaimRecommendationOpen}
                />
              )}
            </div>
          </div>
          <RecommendationWrapper open={isLegalClaimRecommendationOpen}>
            {recommendationForLegalClaim(
              feedbackResultState.leftPaneState.legalClaimErrors,
              currentLanguage
            )}
          </RecommendationWrapper>
          <RecommendationFeedbackCountWrapper
            componentCount={feedbackResultState.leftPaneState.legalClaimCount}
            errorCount={feedbackResultState.leftPaneState.legalClaimErrors}
          />
        </div>
      </div>
      <Divider className="w-full border-gray-300" />
      <div className="my-2">
        <div className="flex justify-between items-between">
          <ComponentCircleExplanation type={LEGAL_TYPE_KONKLUSION} />
          {isConclusionRecommendationOpen ? (
            <ArrowUpwardIcon onClick={toggleConclusionRecommendationOpen} />
          ) : (
            <ArrowDownwardIcon onClick={toggleConclusionRecommendationOpen} />
          )}
        </div>
        <RecommendationWrapper open={isConclusionRecommendationOpen}>
          {recommendationForConclusion(
            feedbackResultState.leftPaneState,
            currentLanguage
          )}
        </RecommendationWrapper>
        <RecommendationFeedbackCountWrapper
          componentCount={feedbackResultState.leftPaneState.conclusionCount}
          errorCount={errorsForConclusion(feedbackResultState.leftPaneState)}
        />
      </div>
    </PaneWrapper>
  )
}

function RecommendationWrapper({ children, open }) {
  return (
    <div
      className={classNames("text-sm flex gap-2 my-2 open-transition", {
        "h-0 opacity-0 hidden": !open,
      })}>
      <TipsAndUpdatesIcon
        style={{ fontSize: 17, marginTop: 2, color: "orange" }}
      />
      {children}
    </div>
  )
}

function RecommendationFeedbackCountWrapper({ componentCount, errorCount }) {
  return (
    <div className="text-sm flex gap-2">
      {FEEDBACK_COUNT_COMPONENT_LABEL}: {componentCount || 0}
      <Divider
        flexItem
        orientation="horizontal"
        style={{ width: 1 }}
        className="bg-gray-300"
      />
      {FEEDBACK_COUNT_ERROR_LABEL}: {errorCount || 0}
    </div>
  )
}
