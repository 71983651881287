import React from "react"
import { getColorForType } from "../constants/types"
import classNames from "classnames"

export default function ComponentCircle({ type, title = "", width = 32, height = 32 }) {
  const style = {
    backgroundColor: getColorForType(type),
  }

  const hasTitle = title.length > 0

  if (!hasTitle) {
    style["width"] = width
    style["height"] = height
  }

  return (
    <div
      className={classNames("rounded-full", { "p-2": hasTitle })}
      style={style}>
      {hasTitle && <>{title}</>}
    </div>
  )
}
