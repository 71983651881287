import DPGroupSetter from "./DPGroupSetter"

export default function AppWrapper({ children }) {
  return (
    <div className="p-4">
      <DPGroupSetter />
      {children}
    </div>
  )
}
