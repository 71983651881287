export function hasFeedback(dpGroup) {
  return (
    dpGroup === 2 ||
    dpGroup === 3 ||
    dpGroup === 4 ||
    dpGroup === 5 ||
    dpGroup === 6 ||
    dpGroup === 7
  )
}

export function hasPlan(dpGroup) {
  return dpGroup === 1 || dpGroup === 4 || dpGroup === 5 || dpGroup === 7
}

export function getYoutubeVideoLink(dpGroup) {
  switch (dpGroup) {
    case 1:
      return "https://youtu.be/OiISBi5ciwI"
    case 2:
      return "https://youtu.be/HOZzgco4TX8"
    case 3:
      return "https://youtu.be/Qj-y3CP8KSQ"
    case 4:
      return "https://www.youtube.com/watch?v=q2H75MJW3_w"
    case 5:
      return "https://www.youtube.com/watch?v=WHzyQrwmChI"
    case 6:
      return "https://www.youtube.com/watch?v=dN9K2wvEU9Q"
    case 7:
      return "https://youtu.be/YZ7H_ITO6mI"
    default:
      console.log("Invalid dpGroup found " + dpGroup)
      return "https://www.youtube.com/watch?v=A44xez77_1A"
    case 7:
      return "https://www.youtube.com/watch?v=A44xez77_1A"
  }
}
