import React, { useCallback, useState } from "react"
import EditorHeader from "../../components/EditorHeader"
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd"
import { Box, TextField, TextareaAutosize } from "@mui/material"
import MyButton from "../../components/Button"
import FeedbackIcon from "@mui/icons-material/Feedback"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import PlanItemContainer from "./PlanItemContainer"
import { ERROR_NO_ERRORS } from "../../constants/plan"
import ErrorIcon from "@mui/icons-material/Error"
import { redirect, useNavigate } from "react-router-dom"

export default function MiddlePlanPane({
  version,
  planItems,
  setPlanItems,
  addPlanItem,
  planErrors,
  validatePlanItems,
}) {
  function handleDragEnd(result) {
    const reorder = (list, startIndex, endIndex) => {
      const result = [...list]
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    let reorderedItems = [...planItems]
    if (result.destination) {
      reorderedItems = reorder(
        planItems,
        result.source.index,
        result.destination.index
      )
    } else {
      // remove
      return
    }

    setPlanItems(reorder(reorderedItems))
  }

  function handleTextChange(planItemIdx, newText) {
    const newPlanItems = [...planItems]
    newPlanItems[planItemIdx] = {
      ...newPlanItems[planItemIdx],
      text: newText,
    }
    setPlanItems(newPlanItems)
  }

  function handleManualCounterChange(planItemIdx, newTextCounter) {
    const newPlanItems = [...planItems]
    newPlanItems[planItemIdx] = {
      ...newPlanItems[planItemIdx],
      manualCounter: newTextCounter,
    }
    setPlanItems(newPlanItems)
  }

  const navigate = useNavigate()

  function handleDelete(planItemIdx) {
    const items = [...planItems]
    items.splice(planItemIdx, 1)
    setPlanItems(items)
  }

  const memoizedHandleDelete = useCallback(handleDelete, [planItems])
  const memoizedHandleTextChange = useCallback(handleTextChange, [planItems])
  const memoizedHandleManualCounterChange = useCallback(
    handleManualCounterChange,
    [planItems]
  )

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#828282" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
  })

  function redirectMain() {
    console.log("finish plan")
    navigate("/main")
  }

  return (
    <>
      <EditorHeader />
      <div className="mt-2">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="flex flex-col rounded"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {planItems.length === 0 && (
                  <Box className="p-2 flex gap-2 bg-yellow-100 rounded mb-2">
                    <FeedbackIcon />
                    <p>
                      Du hast noch keine Strukturierungen ausgewählt. Klick
                      dafür links auf einen der farbigen Buttons.
                    </p>
                  </Box>
                )}
                {planItems.length > 0 && (
                  <Box className="p-2 flex gap-2 bg-green-100 rounded mb-2">
                    <HelpOutlineOutlinedIcon />
                    <p>
                      Du kannst die Strukturierungen mit Drag-and-drop über den
                      farbigen Button ordnen. Links von dem Button kannst du die
                      Strukturierung nummerieren (1-25) und rechts deine
                      Falllösung skizzieren. Zum Entfernen kannst du auf die
                      Tonne klicken. Rechts findest du eine Beispielskizze an
                      der du dich orientieren kannst.
                    </p>
                  </Box>
                )}
                <div
                  className="rounded py-2 bg-white"
                  style={getListStyle(snapshot.isDraggingOver)}>
                  {planItems.map(({ id, type, manualCounter }, idx) => (
                    <Draggable key={id} draggableId={id} index={idx}>
                      {provided => (
                        <div
                          className="planTextItem"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                          {planItems.hasOwnProperty(idx) && (
                            <PlanItemContainer
                              type={type}
                              idx={idx}
                              counterValue={planItems[idx]["manualCounter"]}
                              textValue={planItems[idx]["text"]}
                              handleCounterChange={
                                memoizedHandleManualCounterChange
                              }
                              handleTextChange={memoizedHandleTextChange}
                              handleDelete={memoizedHandleDelete}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {!!planErrors && planErrors.length === 0 && (
        <Box className="p-2 flex gap-2 bg-green-100 rounded mt-2">
          <FeedbackIcon />
          <p>{ERROR_NO_ERRORS}</p>
        </Box>
      )}
      {!!planErrors && planErrors.length > 0 && (
        <Box className="p-2 flex gap-2 bg-red-100 rounded mt-2">
          <ErrorIcon />
          <Box className="flex gap-1 flex-col">
            {planErrors.map(({ text, counter }, idx) => (
              <div key={`planerror-${idx}`}>
                {counter === -1 && <p>{text}</p>}
                {counter != -1 && (
                  <p>
                    <strong>{counter}:</strong> {text}
                  </p>
                )}
              </div>
            ))}
          </Box>
        </Box>
      )}
      <div className="w-full flex gap-4 mt-4 justify-center">
        <MyButton onClick={validatePlanItems}>Validieren</MyButton>
        <MyButton onClick={redirectMain}>Weiter</MyButton>
      </div>
    </>
  )
}
