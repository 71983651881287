import * as React from "react"
import Divider from "@mui/material/Divider"
import PaneWrapper from "./PaneWrapper"
import { ArcherContainer, ArcherElement } from "react-archer"
import {
  NOTE_ON_SUBSUMPTION_CLAIM_EXAMPLE,
  NOTE_ON_SUBSUMPTION_EXAMPLE,
  NOTE_ON_SUBSUMPTION_EXAMPLE_2,
  NOTE_ON_SUBSUMPTION_TEXT,
  NOTE_ON_SUBSUMPTION_TEXT_2,
} from "../../constants/lang"
import PaneText from "./PaneText"
import HtmlTooltip, { HtmlTooltipSimple } from "../HtmlTooltip"
import ComponentCircleExplanation from "../ComponentCircleExplanation"
import { LEGAL_TYPE_CLAIM, LEGAL_TYPE_PREM } from "../../constants/types"
import ComponentCircle from "../ComponentCircle"

export default function NoteOnSubsumptionLeftPane({ state, version, open, setOpen }) {
  return (
    <PaneWrapper title="Hinweise zur Subsumtion" open={open} setOpen={setOpen}>
      <PaneText>{NOTE_ON_SUBSUMPTION_TEXT}</PaneText>
      <div className="my-4">
        <Divider className="w-full" light />
        <div className="py-2">
          {<ComponentCircleExplanation type={LEGAL_TYPE_PREM} />}
        </div>
        <Divider className="w-full" light />
        <div className="py-2">
          {<ComponentCircleExplanation type={LEGAL_TYPE_CLAIM} />}
        </div>
        <Divider className="w-full" light />
      </div>
      <PaneText>{NOTE_ON_SUBSUMPTION_TEXT_2}</PaneText>
      <ArcherContainer strokeColor="lightgray">
        <div className="flex justify-center flex-col gap-16 mt-2 items-center">
          <ArcherElement id="claim-example-component" relations={[]}>
            <div className="">
              <HtmlTooltipSimple
                title={
                  <React.Fragment>
                    <p className="text-base">{NOTE_ON_SUBSUMPTION_CLAIM_EXAMPLE}</p>
                  </React.Fragment>
                }>
                <div>
                  <ComponentCircle type={LEGAL_TYPE_CLAIM} />
                </div>
              </HtmlTooltipSimple>
            </div>
          </ArcherElement>
          <div className="flex gap-4">
            <ArcherElement
              id="prem-example-component"
              relations={[
                {
                  targetId: "claim-example-component",
                  targetAnchor: "bottom",
                  sourceAnchor: "top",
                  // label: "Arrow 3",
                },
              ]}>
              <div className="">
                <HtmlTooltipSimple
                  title={
                    <React.Fragment>
                      <p className="text-base">{NOTE_ON_SUBSUMPTION_EXAMPLE}</p>
                    </React.Fragment>
                  }>
                  <div>
                    <ComponentCircle type={LEGAL_TYPE_PREM} />
                  </div>
                </HtmlTooltipSimple>
              </div>
            </ArcherElement>
            <ArcherElement
              id="prem2-example-component"
              relations={[
                {
                  targetId: "claim-example-component",
                  targetAnchor: "bottom",
                  sourceAnchor: "top",
                },
              ]}>
              <div style={{ padding: "1px" }}>
                <HtmlTooltipSimple
                  title={
                    <React.Fragment>
                      <p className="text-base">{NOTE_ON_SUBSUMPTION_EXAMPLE_2}</p>
                    </React.Fragment>
                  }>
                  <div>
                    <ComponentCircle type={LEGAL_TYPE_PREM} />
                  </div>
                </HtmlTooltipSimple>
              </div>
            </ArcherElement>
          </div>
        </div>
      </ArcherContainer>
    </PaneWrapper>
  )
}
