import * as React from "react"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress, {
} from "@mui/material/CircularProgress"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { loginUrl } from "../constants/env"

// const rows = [
//     {
//         "username": "parsa.neshaei@uni-kassel.de",
//         "text": "Die Checkliste wird dir helfen, die juristische Argumentationsstruktur bzw. dem Gutachtenstil besser einzuhalten.",
//         "time": "1666243117.449121"
//     },
//     {
//         "username": "florian.weber@uni-kassel.de",
//         "text": "Gutachtenstil die Checkliste wird dir helfen, die juristische Argumentationsstruktur bzw. dem Gutachtenstil besser einzuhalten.",
//         "time": "1666242117.449121"
//     }
// ]

const login = (setRows, setLoadingIndicatorOpen) => {
  const key = document.getElementById("key").value
  setLoadingIndicatorOpen(true)
  fetch(loginUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      key: key,
    }),
  })
    .then(response => {
      response
        .json()
        .then(data => {
          setLoadingIndicatorOpen(false)
          if (data["success"] === "true") {
            setRows(JSON.parse(data["texts"]))
          } else {
            setLoadingIndicatorOpen(false)
            alert("Fehler: " + data["error"])
          }
        })
        .catch(err => {
          console.log(err)
          setLoadingIndicatorOpen(false)
          alert("Fehler")
        })
    })
    .catch(err => {
      console.log(err)
      setLoadingIndicatorOpen(false)
      alert("Fehler")
    })
}

const csvFromJson = json => {
  let fields = Object.keys(json[0])
  let replacer = function (key, value) {
    return value === null ? "" : value
  }
  let csv = json.map(function (row) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      })
      .join(",")
  })
  csv.unshift(fields.join(","))
  csv = csv.join("\r\n")
  return csv
}

function downloadCSV(text) {
  var element = document.createElement("a")
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  )
  element.setAttribute("download", "result.csv")
  element.style.display = "none"
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

function AdminPage() {
  const [rows, setRows] = React.useState([])
  const [isLoadingIndicatorOpen, setLoadingIndicatorOpen] = React.useState(
    false
  )
  return (
    <>
      <div className="admin-page-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <h1 style={{ fontSize: "300%", color: "#333" }}>
            Siehe Eingereichte Texte
          </h1>{" "}
          <br />
          <div
            style={{
              backgroundColor: "#333",
              padding: "5%",
              paddingBottom: "3%",
              paddingTop: "4%",
              borderRadius: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <span style={{ color: "rgb(142, 187, 222)", fontSize: 20 }}>
              Administrator-Passwort
            </span>{" "}
            <br />
            <input
              type="password"
              id="key"
              name="key"
              placeholder=""
              style={{
                width: 490,
                height: 30,
                backgroundColor: "rgb(142, 187, 222)",
                borderRadius: 5,
                border: "none",
                resize: "none",
                outline: "none",
                fontSize: "110%",
                textAlign: "center",
                padding: 5,
              }}
            />{" "}
            <br /> <br />
            <button
              style={{
                width: 150,
                height: 50,
                backgroundColor: "rgb(66, 182, 162)",
                borderRadius: 10,
                border: "none",
                resize: "none",
                outline: "none",
                fontSize: "120%",
                textAlign: "center",
                padding: 5,
                cursor: "pointer",
                fontWeight: "bold",
                marginBottom: 10,
              }}
              onClick={() => {
                login(setRows, setLoadingIndicatorOpen)
              }}>
              Anmeldung
            </button>
          </div>
          <br /> <br />
          {rows.length > 0 && (
            <>
              <button
                style={{
                  width: 300,
                  height: 50,
                  backgroundColor: "white",
                  borderRadius: 10,
                  border: "none",
                  resize: "none",
                  outline: "none",
                  fontSize: "120%",
                  textAlign: "center",
                  padding: 5,
                  cursor: "pointer",
                  fontWeight: "bold",
                  marginBottom: 10,
                }}
                onClick={() => {
                  downloadCSV(csvFromJson(rows))
                }}>
                Ergebnisse herunterladen
              </button>
              <br />
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, fontSize: "125%" }}
                  aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Text</TableCell>
                      <TableCell>E-Mail</TableCell>
                      <TableCell>Zeit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow
                        key={row.username + ":" + row.time}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell component="th" scope="row">
                          {row.text}
                        </TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>
                          {new Date(parseInt(row.time * 1000)).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
          <br /> <br /> <br /> <br /> <br /> <br /> <br />
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoadingIndicatorOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default AdminPage
