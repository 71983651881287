import { atom } from "recoil"

export const languageState = atom({
  key: "language",
  default: "de", // en / de
})

export const dpGroupState = atom({
  key: "dpGroup",
  default: -1,
})

// if demo we dont save any data, just let someone play with the tool
export const demoState = atom({
  key: "demo",
  default: false,
})

export const textIdState = atom({
  key: "textId",
  default: "",
})

export const planItemsState = atom({
  key: "planItems", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
})

export const wordCountState = atom({
  key: "wordCount",
  default: 0,
})
