export const paragraphIntroText =
  "Hier kannst du relevante Paragraphen aufklappen und für die Bearbeitung deines Gutachtens nutzen."

export const paragraphContainers = [
  {
    containerTitle: "Fall 1",
    paragraphs: [
      {
        name: "§ 631 BGB Vertragstypische Pflichten beim Werkvertrag",
        text: `(1) Durch den Werkvertrag wird der Unternehmer zur Herstellung des versprochenen Werkes, der Besteller zur Entrichtung der vereinbarten Vergütung verpflichtet.
    
  (2) Gegenstand des Werkvertrags kann sowohl die Herstellung oder Veränderung einer Sache als auch ein anderer durch Arbeit oder Dienstleistung herbeizuführender Erfolg sein.`,
      },
      {
        name: "§ 104 BGB Geschäftsunfähigkeit",
        text: `Geschäftsunfähig ist: 
    
(1.) wer nicht das siebente Lebensjahr vollendet hat,

(2.) wer sich in einem die freie Willensbestimmung ausschließenden Zustand krankhafter Störung der Geistestätigkeit befindet, sofern nicht der Zustand seiner Natur nach ein vorübergehender ist.`,
      },
      {
        name: "§ 105 BGB Nichtigkeit der Willenserklärung",
        text: `(1) Die Willenserklärung eines Geschäftsunfähigen ist nichtig.
    
(2) Nichtig ist auch eine Willenserklärung, die im Zustand der Bewusstlosigkeit oder vorübergehender Störung der Geistestätigkeit abgegeben wird.`,
      },
      {
        name: "§ 106 BGB Beschränkte Geschäftsfähigkeit Minderjähriger",
        text: `Bei der Auslegung einer Willenserklärung ist der wirkliche Wille zu erforschen und nicht an dem buchstäblichen Sinne des Ausdrucks zu haften.`,
      },
      {
        name: "§ 107 BGB Einwilligung des gesetzlichen Vertreters",
        text: `Der Minderjährige bedarf zu einer Willenserklärung, durch die er nicht lediglich einen rechtlichen Vorteil erlangt, der Einwilligung seines gesetzlichen Vertreters.`,
      },
      {
        name: "§ 108 BGB Vertragsschluss ohne Einwilligung",
        text: `(1) Schließt der Minderjährige einen Vertrag ohne die erforderliche Einwilligung des gesetzlichen Vertreters, so hängt die Wirksamkeit des Vertrags von der Genehmigung des Vertreters ab.
    
(2) Fordert der andere Teil den Vertreter zur Erklärung über die Genehmigung auf, so kann die Erklärung nur ihm gegenüber erfolgen; eine vor der Aufforderung dem Minderjährigen gegenüber erklärte Genehmigung oder Verweigerung der Genehmigung wird unwirksam. Die Genehmigung kann nur bis zum Ablauf von zwei Wochen nach dem Empfang der Aufforderung erklärt werden; wird sie nicht erklärt, so gilt sie als verweigert.

(3) Ist der Minderjährige unbeschränkt geschäftsfähig geworden, so tritt seine Genehmigung an die Stelle der Genehmigung des Vertreters.`,
      },
      {
        name: "§ 110 BGB Bewirken der Leistung mit eigenen Mitteln",
        text: `Ein von dem Minderjährigen ohne Zustimmung des gesetzlichen Vertreters geschlossener Vertrag gilt als von Anfang an wirksam, wenn der Minderjährige die vertragsmäßige Leistung mit Mitteln bewirkt, die ihm zu diesem Zweck oder zu freier Verfügung von dem Vertreter oder mit dessen Zustimmung von einem Dritten überlassen worden sind.`,
      },
    ],
  },
  {
    containerTitle: "Fall 2",
    paragraphs: [
      {
        name: "§ 433 BGB Vertragstypische Pflichten beim Kaufvertrag",
        text: `(1) Durch den Kaufvertrag wird der Verkäufer einer Sache verpflichtet, dem Käufer die Sache zu übergeben und das Eigentum an der Sache zu verschaffen. Der Verkäufer hat dem Käufer die Sache frei von Sach- und Rechtsmängeln zu verschaffen.
    
(2) Der Käufer ist verpflichtet, dem Verkäufer den vereinbarten Kaufpreis zu zahlen und die gekaufte Sache abzunehmen.`,
      },
      {
        name: "§ 145 BGB  Bindung an den Antrag",
        text: `Wer einem anderen die Schließung eines Vertrags anträgt, ist an den Antrag gebunden, es sei denn, dass er die Gebundenheit ausgeschlossen hat.`,
      },
      {
        name: "§ 133 BGB  Auslegung einer Willenserklärung",
        text: `Bei der Auslegung einer Willenserklärung ist der wirkliche Wille zu erforschen und nicht an dem buchstäblichen Sinne des Ausdrucks zu haften.`,
      },
      {
        name: "§ 157 BGB Auslegung von Verträgen",
        text: `Verträge sind so auszulegen, wie Treu und Glauben mit Rücksicht auf die Verkehrssitte es erfordern.`,
      },
    ],
  },
  {
    containerTitle: "Fall 3",
    paragraphs: [
      {
        name: "§ 433 BGB Vertragstypische Pflichten beim Kaufvertrag",
        text: `(1) Durch den Kaufvertrag wird der Verkäufer einer Sache verpflichtet, dem Käufer die Sache zu übergeben und das Eigentum an der Sache zu verschaffen. Der Verkäufer hat dem Käufer die Sache frei von Sach- und Rechtsmängeln zu verschaffen.
    
(2) Der Käufer ist verpflichtet, dem Verkäufer den vereinbarten Kaufpreis zu zahlen und die gekaufte Sache abzunehmen.`,
      },
      {
        name: "§ 143 BGB Anfechtungserklärung",
        text: `(1) Die Anfechtung erfolgt durch Erklärung gegenüber dem Anfechtungsgegner.
    
(2) Anfechtungsgegner ist bei einem Vertrag der andere Teil, im Falle des § 123 Abs. 2 Satz 2 derjenige, welcher aus dem Vertrag unmittelbar ein Recht erworben hat.

(3) Bei einem einseitigen Rechtsgeschäft, das einem anderen gegenüber vorzunehmen war, ist der andere der Anfechtungsgegner. Das Gleiche gilt bei einem Rechtsgeschäft, das einem anderen oder einer Behörde gegenüber vorzunehmen war, auch dann, wenn das Rechtsgeschäft der Behörde gegenüber vorgenommen worden ist.

(4) Bei einem einseitigen Rechtsgeschäft anderer Art ist Anfechtungsgegner jeder, der auf Grund des Rechtsgeschäfts unmittelbar einen rechtlichen Vorteil erlangt hat. Die Anfechtung kann jedoch, wenn die Willenserklärung einer Behörde gegenüber abzugeben war, durch Erklärung gegenüber der Behörde erfolgen; die Behörde soll die Anfechtung demjenigen mitteilen, welcher durch das Rechtsgeschäft unmittelbar betroffen worden ist.`,
      },
      {
        name: "§ 133 BGB Auslegung einer Willenserklärung",
        text: `Bei der Auslegung einer Willenserklärung ist der wirkliche Wille zu erforschen und nicht an dem buchstäblichen Sinne des Ausdrucks zu haften.`,
      },
      {
        name: "§ 157 BGB Auslegung von Verträgen",
        text: `Verträge sind so auszulegen, wie Treu und Glauben mit Rücksicht auf die Verkehrssitte es erfordern.`,
      },
      {
        name: "§ 119 BGB Anfechtbarkeit wegen Irrtums",
        text: `(1) Wer bei der Abgabe einer Willenserklärung über deren Inhalt im Irrtum war oder eine Erklärung dieses Inhalts überhaupt nicht abgeben wollte, kann die Erklärung anfechten, wenn anzunehmen ist, dass er sie bei Kenntnis der Sachlage und bei verständiger Würdigung des Falles nicht abgegeben haben würde.
    
(2) Als Irrtum über den Inhalt der Erklärung gilt auch der Irrtum über solche Eigenschaften der Person oder der Sache, die im Verkehr als wesentlich angesehen werden.`,
      },
      {
        name: "§ 121 BGB Anfechtungsfrist",
        text: `(1) Die Anfechtung muss in den Fällen der §§ 119, 120 ohne schuldhaftes Zögern (unverzüglich) erfolgen, nachdem der Anfechtungsberechtigte von dem Anfechtungsgrund Kenntnis erlangt hat. Die einem Abwesenden gegenüber erfolgte Anfechtung gilt als rechtzeitig erfolgt, wenn die Anfechtungserklärung unverzüglich abgesendet worden ist.
    
(2) Die Anfechtung ist ausgeschlossen, wenn seit der Abgabe der Willenserklärung zehn Jahre verstrichen sind.`,
      },
      {
        name: "§ 142 BGB  Wirkung der Anfechtung",
        text: `(1) Wird ein anfechtbares Rechtsgeschäft angefochten, so ist es als von Anfang an nichtig anzusehen.
    
(2) Wer die Anfechtbarkeit kannte oder kennen musste, wird, wenn die Anfechtung erfolgt, so behandelt, wie wenn er die Nichtigkeit des Rechtsgeschäfts gekannt hätte oder hätte kennen müssen.`,
      },
    ],
  },
]
