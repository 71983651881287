const numberOfWordsInSentence = sentence => {
  if (sentence.trim().length > 2) {
    return 1
  }
  return 0
  // let parts = sentence.split(" ");
  // let count = 0;
  // for (let i = 0; i < parts.length; i++) {
  //   if (parts[i].trim().length > 2) {‚
  //     count += 1;
  //   }
  // }
  // return count;
}

export { numberOfWordsInSentence }

export function capitalize(str) {
  if (!str) return str

  const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1)
  return capitalizedStr
}

export function checkComponentOrder(arr1, arr2) {
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}
