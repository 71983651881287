import * as React from "react"
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import { useRecoilState } from "recoil"
import { languageState } from "../../state/recoil"
import HtmlTooltip, {
  TooltipMore,
  TooltipText,
  TooltipTitle,
} from "../HtmlTooltip"
import LinearDeterminate from "../LinearDeterminate"
import { Divider } from "@mui/material"
import PaneWrapper from "./PaneWrapper"
import PaneText from "./PaneText"
import ComponentCircle from "../ComponentCircle"
import ComponentCircleExplanation from "../ComponentCircleExplanation"
import {
  LEGAL_TYPE_DEFINITION,
  LEGAL_TYPE_KONKLUSION,
  LEGAL_TYPE_OBERSATZ,
  LEGAL_TYPE_SUBSUMPTION,
} from "../../constants/types"
import { FEEDBACK_NOT_YET_RETRIEVED } from "../../constants/lang"
import {
  errorsForConclusion,
  errorsForDefinition,
  errorsForMajorClaim,
} from "../../lib/recommendations"

export default function FeedbackPane({ feedbackResultState, open, setOpen }) {
  const [currentLanguage] = useRecoilState(languageState)
  // let data = [{name: "Major Claim", value: state.rightPanelState.majorClaimPercentage}, {name: "Conclusion", value: state.rightPanelState.conclusionPercentage}, {name: "Definition", value: state.rightPanelState.definitionPercentage}, {name: "Subsumption", value: state.rightPanelState.subsumptionPercentage}, {name: "Legal Claim", value: state.rightPanelState.legalClaimPercentage}, {name: "Premise", value: state.rightPanelState.premisePercentage}];
  // calculate percentages of state.rightPanelState.majorClaimPercentage, state.rightPanelState.conclusionPercentage, state.rightPanelState.definitionPercentage, state.rightPanelState.subsumptionPercentage
  let sum =
    feedbackResultState.rightPanelState.majorClaimPercentage +
    feedbackResultState.rightPanelState.conclusionPercentage +
    feedbackResultState.rightPanelState.definitionPercentage +
    feedbackResultState.rightPanelState.subsumptionPercentage
  let data = []
  let colors = ["white"]
  if (feedbackResultState.rightPanelState.majorClaimPercentage > 0) {
    data.push({
      name: currentLanguage === "en" ? "Major Claim" : "Obersatz",
      value: Math.round(
        (100 * feedbackResultState.rightPanelState.majorClaimPercentage) / sum
      ),
    })
    colors.push("lightsalmon")
  }
  if (feedbackResultState.rightPanelState.conclusionPercentage > 0) {
    data.push({
      name: currentLanguage === "en" ? "Conclusion" : "Konklusion",
      value: Math.round(
        (100 * feedbackResultState.rightPanelState.conclusionPercentage) / sum
      ),
    })
    colors.push("pink")
  }
  if (feedbackResultState.rightPanelState.definitionPercentage > 0) {
    data.push({
      name: currentLanguage === "en" ? "Definition" : "Definition",
      value: Math.round(
        (100 * feedbackResultState.rightPanelState.definitionPercentage) / sum
      ),
    })
    colors.push("violet")
  }
  // console.log(JSON.stringify(feedbackResultState.rightPanelState))
  // console.log("checking if subusumption push feedbackResultState.rightPanelState.subsumptionPercentage =" + feedbackResultState.rightPanelState.subsumptionPercentage)
  if (feedbackResultState.rightPanelState.subsumptionPercentage > 0) {
    data.push({
      name: currentLanguage === "en" ? "Subsumption" : "Subsumtion",
      value: Math.round(
        (100 * feedbackResultState.rightPanelState.subsumptionPercentage) / sum
      ),
    })
    colors.push("cyan")
  }

  const score = React.useMemo(() => {
    let baseScore =
      parseInt(feedbackResultState.rightPanelState.otherPercentage) || 0

    const errorCounts =
      feedbackResultState.leftPaneState.premiseErrors +
      feedbackResultState.leftPaneState.legalClaimErrors +
      errorsForMajorClaim(feedbackResultState.leftPaneState) +
      errorsForConclusion(feedbackResultState.leftPaneState) +
      errorsForDefinition(feedbackResultState.leftPaneState)

    baseScore -= errorCounts * 2

    return Math.max(0, baseScore)
  }, [feedbackResultState])

  return (
    <PaneWrapper
      title="Dashboard für juristische Argumentation"
      className="dashboard"
      open={open}
      setOpen={setOpen}>
      <PaneText>
        {currentLanguage === "en"
          ? "In addition to the checklist, the dashboard provides you with overall feedback."
          : "Zusätzlich zur Checkliste bietet dir das Dashboard ein allgemeines Feedback."}
      </PaneText>
      <div className="flex flex-wrap gap-4 items-center justify-center my-4">
        <ComponentCircleExplanation type={LEGAL_TYPE_OBERSATZ} labelInCircle />
        <ComponentCircleExplanation
          type={LEGAL_TYPE_DEFINITION}
          labelInCircle
        />
        <ComponentCircleExplanation
          type={LEGAL_TYPE_SUBSUMPTION}
          labelInCircle
        />
        <ComponentCircleExplanation
          type={LEGAL_TYPE_KONKLUSION}
          labelInCircle
        />
      </div>
      {!feedbackResultState.feedbackRetrieved && (
        <>
          <Divider className="w-full" light />
          <PaneText className="mt-2">{FEEDBACK_NOT_YET_RETRIEVED}</PaneText>
        </>
      )}
      {feedbackResultState.feedbackRetrieved && (
        <div className="mt-4">
          <h3 className="text-bold text-large">
            {currentLanguage === "en"
              ? "Distribution of Components"
              : "Verteilung der Komponenten"}
          </h3>
          <Divider className="w-full" light />
          <PaneText className="mt-2">
            {currentLanguage === "en"
              ? "In this graphic you can see what percentage of the sentences do individual components have."
              : "In dieser Grafik siehst du, wie viel Prozent der Sätze auf die einzelnen Komponenten entfallen."}
            <HtmlTooltip
              title={
                <>
                  <TooltipTitle>
                    {currentLanguage === "en"
                      ? "Distribution chart"
                      : "Verteilungsdiagramm"}
                  </TooltipTitle>
                  <TooltipText>
                    {currentLanguage === "en"
                      ? "It is important that the subsumption takes the largest part, therefore here the argumentative weighing of the facts and the conditions of the case takes place."
                      : "Das Diagramm zeigt dir die Zusammensetzung deines Textes an. Juristische Falllösungen beschäftigen sich mit rechtlichen Problemen, um diese lösen zu können, müssen vor allem die Definitionen die abstrakten Voraussetzungen des im Obersatz aufgeworfenen Prüfungsgegenstands liefern. Demnach wird die Definition meistens den Größenteil deiner Falllösung ausmachen. Um die Voraussetzungen zu prüfen, die in der Definition benannt wurden, solltest du in der Subsumtion die Voraussetzungen mit den Tatsachen aus dem Fall abgleichen. Demnach sollte die Subsumtion meistens den zweit größten Teil deiner Falllösung ausmachen. Zeigt dir das Diagramm eine andere Zusammensetzung an, nimmt zum Beispiel die Konklusion den größten Teil deines Textes ein, solltest du diesen eventuell überarbeiten."}
                  </TooltipText>
                </>
              }>
              <TooltipMore />
            </HtmlTooltip>
          </PaneText>
          <ResponsiveContainer height={280}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value,
                  index,
                }) => {
                  // console.log("handling label?")
                  const RADIAN = Math.PI / 180
                  // eslint-disable-next-line
                  const radius = 25 + innerRadius + (outerRadius - innerRadius)
                  // eslint-disable-next-line
                  const x = cx + radius * Math.cos(-midAngle * RADIAN)
                  // eslint-disable-next-line
                  const y = cy + radius * Math.sin(-midAngle * RADIAN)

                  return (
                    <text
                      x={x}
                      y={y}
                      fill={"black"}
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline="central"
                      fontSize={12}>
                      {data[index].name} ({value}%)
                    </text>
                  )
                }}>
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[(index % colors.length) + 1]}
                  />
                ))}
              </Pie>

              {/* <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" label={true} labelLine={true} innerRadius={60} outerRadius={80} legendType="rect" fill="#8884d8">
            {
              data.map((entry, index) => <Cell fill={colors[index % colors.length + 1]}/>)
            }
          </Pie> */}
            </PieChart>
          </ResponsiveContainer>
          <h3 className="text-bold text-large">
            {currentLanguage === "en"
              ? "Persuasiveness Score"
              : "Verwendung und Einhaltung des Gutachtenstils"}
          </h3>
          <Divider className="w-full" light />
          <PaneText className="mt-2 mb-4">
            {currentLanguage === "en"
              ? "This graph shows you how much of your text follows the argument structure."
              : "Dieses Diagramm zeigt dir, wie viel von deinem Text der dem Gutachtenstil bzw. einer guten Argumentationsstruktur folgt."}
            <HtmlTooltip
              title={
                <>
                  <TooltipTitle>
                    {currentLanguage === "en"
                      ? "Persuasiveness score"
                      : "Verwendung und Einhaltung des Gutachtenstils"}
                  </TooltipTitle>
                  <TooltipText>
                    {currentLanguage === "en"
                      ? "The less text that can be assigned, the more carefully you should revise your case solution."
                      : "Je weniger Text zugewiesen werden kann, desto sorgfältiger solltest du deine Falllösung überarbeiten. Beachtet bitte, dass die prozentuale Anzeige unten dir nur einen groben Überblick über die Einhaltung des Gutachtenstils bietet. Achte auch darauf, dass die einzelnen Komponenten des Gutachtenstils sinnvoll miteinander verbunden sind. Zum Beispiel muss jeder Obersatz am Ende auch durch eine Konklusion beantwortet werden. Eine 100% bedeutet nicht, dass du eine perfekte Falllösung geschrieben hast, sondern nur, dass du den Gtuachtenstil stringent eingehalten hast. Wenn deine prozentuale Angabe zwischen 60% und 100% ist, ist dies ein Indikator dafür, dass der Gutachtenstil größtenteils eingehalten wurde. Ist der Wert geringer Versuche, genauer auf die Einhaltung des Gutachtenstils zu achten."}
                  </TooltipText>
                </>
              }>
              <TooltipMore />
            </HtmlTooltip>
          </PaneText>
          <LinearDeterminate progress={score} />
        </div>
      )}
    </PaneWrapper>
  )
}
