import React from "react"
import classnames from "classnames"
import PlanItemButton from "./PlanItemButton"
import {
  LEGAL_TYPE_UEBERSCHRIFT,
  getColorForType,
  getLabelForType,
} from "../../constants/types"
import { TextField, Input } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

export default function PlanItemContainer({
  type,
  idx,
  handleTextChange = () => {},
  handleCounterChange = () => {},
  handleDelete,
  textValue,
  counterValue,
  small,
}) {
  const label = small
    ? `${counterValue}. ${getLabelForType(type)}`
    : getLabelForType(type)
  const isInputVisible = !small

  return (
    <div
      className={classnames("w-full", { "flex gap-2": !small, "mb-2": small })}>
      {isInputVisible && (
        <div
          className={classnames("bg-gray-200 p-2 shrink-0 rounded", {
            "opacity-0": false, //type !== LEGAL_TYPE_UEBERSCHRIFT,
          })}
          style={{ width: 52, maxHeight: 48 }}>
          <Input
            type="number"
            min={1}
            value={counterValue}
            disabled={type !== LEGAL_TYPE_UEBERSCHRIFT}
            onChange={event => handleCounterChange(idx, event.target.value)}
            inputProps={{ min: 1, max: 25 }}
          />
        </div>
      )}
      <PlanItemButton
        id={type}
        className={classnames({ "w-full mb-2": small })}
        label={label}
        backgroundColor={getColorForType(type)}
        small={small}
      />
      <TextField
        multiline
        className={classnames("w-full", {
          "flex-grow": !small,
          planTextItemTextAreaSmall: type === "ueberschrift",
        })}
        value={textValue}
        inputProps={{
          style: small ? { fontSize: 11, lineHeight: 1.35 } : {},
          classes: {
            input: classnames({ "text-sm": small }),
          },
        }}
        spellCheck={false}
        onChange={event => handleTextChange(idx, event.target.value)}
      />
      {handleDelete && (
        <div className="flex items-center">
          <DeleteIcon
            className="text-gray-600 hover:text-gray-900"
            onClick={() => handleDelete(idx)}
          />
        </div>
      )}
    </div>
  )
}
