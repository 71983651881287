import * as React from "react"
import Divider from "@mui/material/Divider"
import { useRecoilState } from "recoil"
import HtmlTooltip from "../../components/HtmlTooltip"
import { Backdrop, CircularProgress } from "@mui/material"
import {
  demoState,
  dpGroupState,
  languageState,
  textIdState,
  wordCountState,
} from "../../state/recoil"
import EditorHeader from "../../components/EditorHeader"
import { Box, TextField, TextareaAutosize } from "@mui/material"
import FeedbackIcon from "@mui/icons-material/Feedback"
import MyButton from "../../components/Button"
import {
  FEEDBACK_BUTTON_CLICK_HELP,
  FEEDBACK_EXPLANATION_TOOLTIP_MAP,
  TITLE_TEXT_EDITOR,
} from "../../constants/lang"
import { hasFeedback } from "../../lib/dp"
import { submitTextResult, retrieveFeedbackForText } from "../../lib/feedback"
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom"
import { useQuery } from "../../lib/customHooks"
import { getLatestTextForId } from "../../lib/texts"

let keydowns = []

export default function MiddleMainPane({
  feedbackResultState,
  setFeedbackResultState,
  version,
}) {
  const [isLoadingIndicatorOpen, setLoadingIndicatorOpen] =
    React.useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const hasUnloadedTextId = React.useRef(true)
  const inputRef = React.useRef(null)

  const [currentLanguage] = useRecoilState(languageState)
  const [dpGroup] = useRecoilState(dpGroupState)
  const [isDemo] = useRecoilState(demoState)
  const [wordCount, setWordCount] = useRecoilState(wordCountState)
  const [textId, setTextId] = useRecoilState(textIdState)

  React.useEffect(() => {
    if (!inputRef) {
      return
    }
    if (!hasUnloadedTextId.current) {
      return
    }
    // this should be run once
    const unloadedTextId = searchParams.get("textId")
    if (!unloadedTextId) {
      inputRef.current.focus()
      return
    }

    async function handleLatestTextLoad() {
      setTextId(unloadedTextId)

      if (!hasUnloadedTextId.current) {
        return
      }
      hasUnloadedTextId.current = false

      const latestTextData = await getLatestTextForId(unloadedTextId)
      const latestText = latestTextData?.text ?? ""
      if (latestText) {
        inputRef.current.innerHTML += latestText.replaceAll("\n", "<br/>")
      }

      // not focused
      if (inputRef.current !== document.activeElement) {
      }

      inputRef.current.focus()
    }

    handleLatestTextLoad()
  }, [inputRef])

  React.useEffect(() => {
    if (!isDemo && textId) {
      setSearchParams({ textId })
    }
  }, [textId, isDemo])

  React.useEffect(() => {
    if (!inputRef) {
      return
    }

    inputRef.current.addEventListener("paste", handlePasteNoFormat)
    console.log("adding eventlsitener")
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("paste", handlePasteNoFormat)
      }
    }
  }, [inputRef])

  const handleKeyUp = event => {
    const username = localStorage.getItem("username")
    const textEditorElement = document.getElementById("text-editor")

    if (isDemo || (username && username.length > 0)) {
      keydowns.push({ key: event.key, time: new Date().getTime() })
      setWordCount(textEditorElement.innerText.trim().split(/\s+/).length)
    } else {
      alert("Fehler: bitte melde dich an, um den Editor nutzen zu können.")
    }
  }

  const feedbackExplanationTooltipDpGroup =
    FEEDBACK_EXPLANATION_TOOLTIP_MAP[dpGroup]

  const isFeedback = hasFeedback(dpGroup)

  const shouldSaveText = !isDemo

  return (
    <>
      <EditorHeader />
      <div className="mt-2">
        <Box className="p-2 flex gap-2 bg-green-100 rounded mb-2">
          <FeedbackIcon />
          <p>{TITLE_TEXT_EDITOR}</p>
        </Box>
        <div
          ref={inputRef}
          autoFocus
          contentEditable="true"
          spellCheck="false"
          className="bg-white rounded p-4 mb-4 text-editor whitespace-pre-line"
          id="text-editor"
          onKeyUp={handleKeyUp}
        />
        <div className="flex justify-center items-center gap-4 mb-4">
          {isFeedback && (
            <MyButton
              onClick={async () => {
                const { textId: newTextId } = await retrieveFeedbackForText(
                  dpGroup,
                  textId,
                  setLoadingIndicatorOpen,
                  feedbackResultState,
                  setFeedbackResultState,
                  setWordCount,
                  keydowns,
                  shouldSaveText
                )

                if (!isDemo) {
                  setTextId(newTextId)
                  console.log("retrieved feedback for textId " + newTextId)
                }
              }}>
              Feedback
            </MyButton>
          )}
          {!isDemo && (
            <MyButton
              onClick={async () =>
                await submitTextResult(
                  dpGroup,
                  textId,
                  setLoadingIndicatorOpen,
                  keydowns,
                  navigate
                )
              }>
              Einreichen
            </MyButton>
          )}
        </div>
        {isFeedback && (
          <Box className="p-2 flex gap-2 bg-green-100 rounded mb-2">
            <FeedbackIcon />
            <div className="flex justify-end items-end">
              <p>{FEEDBACK_BUTTON_CLICK_HELP}</p>
              <HtmlTooltip
                title={
                  <p className="text-base font-normal">
                    {feedbackExplanationTooltipDpGroup}
                  </p>
                }>
                <span>
                  <span style={{ color: "blue", textDecoration: "underline" }}>
                    {currentLanguage === "en" ? "More" : "Mehr"}
                  </span>
                </span>
              </HtmlTooltip>
            </div>
          </Box>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoadingIndicatorOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  )
}

function handlePasteNoFormat(e) {
  e.preventDefault()

  console.log("adding paste")
  let text = ""
  if (e.clipboardData) {
    text = (e.originalEvent || e).clipboardData.getData("text/plain")

    document.execCommand("insertText", false, text)
  } else if (window.clipboardData) {
    text = window.clipboardData.getData("Text")

    document.selection.createRange().pasteHTML(text)
  }
}
