import React from "react"
import PaneWrapper from "./PaneWrapper"
import PlanItemContainer from "../../pages/plan/PlanItemContainer"
import { useRecoilState } from "recoil"
import { planItemsState } from "../../state/recoil"
import PaneText from "./PaneText"
import { NO_PLAN_MADE } from "../../constants/lang"
import {
  LEGAL_TYPE_DEFINITION,
  LEGAL_TYPE_KONKLUSION,
  LEGAL_TYPE_OBERSATZ,
  LEGAL_TYPE_UEBERSCHRIFT,
  LEGAL_TYPE_SUBSUMPTION,
} from "../../constants/types"

export default function ExamplePlanPane({ wrapperClassName, open, setOpen}) {
  const [planItems] = useRecoilState(planItemsState)

  return (
    <PaneWrapper
      title="Beispielskizze"
      className={wrapperClassName}
      open={open} setOpen={setOpen}>
      <div className="">
        <PlanItemContainer
          type={LEGAL_TYPE_UEBERSCHRIFT}
          textValue="Kaufvertrag über einen Drachen zwischen R und V"
          counterValue={1}
          idx={1}
          small
        />
        <PlanItemContainer
          type={LEGAL_TYPE_OBERSATZ}
          textValue="Kaufvertrag gem. § 433 BGB II BGB zustande gekommen?"
          counterValue={1}
          idx={2}
          small
        />
        <PlanItemContainer
          type={LEGAL_TYPE_DEFINITION}
          textValue="§ 433 II BGB = (2) Der Käufer ist verpflichtet, dem Käufer den vereinbarten Kaufpreis zu zahlen und die gekaufte Sache abzunehmen"
          counterValue={1}
          idx={3}
          small
        />
        <PlanItemContainer
          type={LEGAL_TYPE_UEBERSCHRIFT}
          textValue="Angebot"
          counterValue={2}
          idx={4}
          small
        />
        <PlanItemContainer
          type={LEGAL_TYPE_OBERSATZ}
          textValue="Angebot entstanden?"
          counterValue={2}
          idx={5}
          small
        />
        <PlanItemContainer
          type={LEGAL_TYPE_UEBERSCHRIFT}
          textValue="Das Angebot ist also eine private Willensäußerung"
          counterValue={2}
          idx={6}
          small
        />
        <PlanItemContainer
          type={LEGAL_TYPE_SUBSUMPTION}
          textValue="V fragt R nach Drachen"
          counterValue={2}
          idx={7}
          small
        />
        <PlanItemContainer
          type={LEGAL_TYPE_KONKLUSION}
          textValue="Angebot (+)"
          counterValue={2}
          idx={8}
          small
        />
      </div>
    </PaneWrapper>
  )
}
